import React, { useState } from "react";
import Button from "components/Button";
import warning from "images/warning.svg";
import styles from "./disclaimer.module.scss";

interface Props {
  onContinue: Function;
}

export default function Disclaimer(props : Props) {
  const [firstCheck, setFirstCheck] = useState(false);
  const [secondCheck, setSecondCheck] = useState(false);

  return (
    <div className={styles.disclaimer}>
      <header>
        <img
          src={warning}
          alt="warning"
        />
        Please read before uploading!
      </header>

      <div className={styles.disclaimerBody}>
        <p>If you need to acquire releases or want to learn more about releases, visit <a href="https://filmmakers.filmsupply.com/releases" target="_blank" rel="noopener noreferrer"><b>filmmakers.filmsupply.com/releases</b></a></p>
        <p> If you have any questions regarding releases and when they are required, contact your filmmaker rep or <a href="mailto:content@filmsupply.com"><b>content@filmsupply.com</b></a></p>

        <div className={styles.checkContainer}>
          <div className="application-input-section">
            <div className="checkmark-section">
              <label className="application-label" htmlFor="firstCheck">
                <input
                  id="firstCheck"
                  name="firstCheck"
                  type="checkbox"
                  onChange={() => setFirstCheck(!firstCheck)}
                />
                I have complete legal authority to license and profit from the footage I’m about to upload.
              </label>
            </div>
          </div>
          
          <div className="application-input-section">
            <div className="checkmark-section">
              <label className="application-label" htmlFor="secondCheck">
                <input
                  id="secondCheck"
                  name="secondCheck"
                  type="checkbox"
                  onChange={() => setSecondCheck(!secondCheck)}
                />
                I have releases that legally cover me for licensing and I have a release for every talent in every clip I’m uploading for this shoot/film.
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.disclaimerFooter}>
        <Button
          disabled={!firstCheck || !secondCheck}
          onClick={() => props.onContinue()}
        >
          Continue
        </Button>
      </div>
    </div>
  );
}