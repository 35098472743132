import axios from "axios";
import React, { useState, useEffect, Fragment } from "react";
import { generate } from "shortid";
import "./royalty-splits.scss";
import warning from "./images/warning.svg";
import trash from "./images/trash.svg";
import check from "./images/check.svg";
import backArrow from "./images/back-arrow.svg";
var validator = require("email-validator");

interface Payee {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  save: boolean;
  percentageValue: any;
  new: boolean;
  load: boolean;
  warning: boolean;
  updatedEmail: string;
  lastEmailSearch: string;
}

export default function RoyaltySplit(props: any) {
  const [payee, setPayee] = useState<Payee[]>([]);
  const [admin, setAdmin] = useState<any>([]);
  const [filmmaker, setFilmmaker] = useState<any>([]);
  const [filmmakerInput, setFilmmakerInput] = useState<any>(false);
  const [confirmation, setConfirmation] = useState<any>(false);
  const [loadError, setLoadError] = useState<any>(null);
  const [loadSuccess, setLoadSuccess] = useState<any>(null);
  const [collectionName, setCollectionName] = useState<any>("");
  const [collectionId, setCollectionId] = useState<any>("");
  const [clipId, setClipId] = useState<any>("");
  const [clipsSelected, setClipsSelected] = useState<any>("");
  const [clipsInCollection, setClipsInCollection] = useState<any>("");
  const [formSuccess, setFormSuccess] = useState<boolean | null>(false);
  const [submission, setSubmission] = useState<boolean | null>(false);
  const [formData, setFormData] = useState<any>("");
  const [emailLoading, setEmailLoading] = useState<any>(false);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const profileId: any = params.get("profile_id");
    const collection_name = params.get("collection_name");
    const collectionId = params.get("collectionId");
    const clipId: any = params.get("clipID");
    const clipsSelected = params.get("clipsSelected");
    const clipsInCollection = params.get("clipsInCollection");

    const maskedId = profileId / 1067685;

    document.title = "Royalty Splits";
    setCollectionName(collection_name);
    setClipsSelected(clipsSelected);
    setClipsInCollection(clipsInCollection);
    setCollectionId(collectionId);

    const separatedClips = clipId.replaceAll(",", ", ");

    setClipId(separatedClips);

    setPayee([
      {
        id: generate(),
        firstName: "",
        lastName: "",
        email: "",
        save: false,
        percentageValue: "",
        new: false,
        load: false,
        warning: false,
        updatedEmail: "",
        lastEmailSearch: ""
      }
    ]);

    axios({
      method: "POST",
      data: {
        filmmaker_id: maskedId
      },
      url: `${process.env.REACT_APP_API_URL}/royalty-filmmaker`,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY
      }
    }).then(function(res) {
      if (res.data) {
        setAdmin(res.data.admin);
        setFilmmaker(res.data.filmmaker);
        setLoadSuccess(true);

        setFormData({
          profile_name: filmmaker.profileName,
          shoot_name: collection_name,
          clip_id: clipId,
          filmmaker_id: profileId,
          shoot_id: collectionId,
          clips_selected: clipsSelected,
          clips_in_collection: clipsInCollection,
          filmmaker_percentage: ""
        });
      }
      if (!res.data) {
        setLoadError(true);
        setLoadSuccess(true);
      }
    });
  }, [props.location.search, filmmaker.profileName]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // if ((e.target.name = "filmmaker_percentage")) {
    //   setFormData({ ...formData, [e.target.name]: Math.round(parseInt(e.target.value)) });
    //   console.log(e.target.value);
    // } else {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // }
  };

  function updateFilmmakerInput() {
    if (filmmakerInput) {
      setFilmmakerInput(false);
    }
    if (!filmmakerInput) {
      setFilmmakerInput(true);
    }
  }

  const percentageTotal =
    payee.map(item => item.percentageValue).reduce((prev, next) => parseFloat(prev) + parseFloat(next), 0) +
    parseFloat(formData.filmmaker_percentage);

  function submitRoyalties(e: any) {
    setSubmission(true);
    axios({
      method: "POST",
      data: {
        form: { formData },
        payees: JSON.stringify(payee)
      },
      url: `${process.env.REACT_APP_API_URL}/royalties`,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY
      }
    }).then(function(res) {
      setConfirmation(false);
      setFormSuccess(true);
    });
  }

  function findPayee(e: any) {
    axios({
      method: "POST",
      data: {
        email: e[0].email
      },
      url: `${process.env.REACT_APP_API_URL}/royalty-payee`,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY
      }
    }).then(function(res) {
      setPayee(currentPayee =>
        currentPayee.map(x =>
          x.id === e[0].id
            ? {
                ...x,
                load: true
              }
            : x
        )
      );

      if (!res.data) {
        setPayee(currentPayee =>
          currentPayee.map(x =>
            x.id === e[0].id
              ? {
                  ...x,
                  new: true
                }
              : x
          )
        );
      }
      if (res.data) {
        setPayee(currentPayee =>
          currentPayee.map(x =>
            x.id === e[0].id
              ? {
                  ...x,
                  new: false
                }
              : x
          )
        );
      }
    });
  }

  const filmmakerValidation =
    formData.filmmaker_percentage === null ||
    formData.filmmaker_percentage === "" ||
    parseFloat(formData.filmmaker_percentage) >= 100;

  const formValidation =
    formData.filmmaker_percentage === null ||
    formData.filmmaker_percentage === "" ||
    payee.length < 1 ||
    payee === undefined ||
    payee.filter(e => e.firstName.length < 2).length > 0 ||
    payee.filter(e => e.lastName.length < 2).length > 0 ||
    payee.filter(e => e.email === "" || !validator.validate(e.email)).length > 0 ||
    payee.filter(e => e.percentageValue === null || e.percentageValue === "").length > 0 ||
    payee.filter(e => e.save !== true).length > 0 ||
    percentageTotal !== 100 ||
    !filmmakerInput;

  function backToFootageManager() {
    window.open(`https://www.filmsupply.com/dashboard/footage-manager/shoots/${collectionId}/clips`, "_self");
  }

  function editRoyalties() {
    setConfirmation(false);
  }

  function emailFunction(e: any, p: any) {
    const email = p.email;

    if (p.email !== p.lastEmailSearch) {
      setPayee(currentPayee =>
        currentPayee.map(x =>
          x.email === email
            ? {
                ...x,
                updatedEmail: ""
              }
            : x
        )
      );

      setPayee(currentPayee =>
        currentPayee.map(x =>
          x.email === p.email
            ? {
                ...x,
                lastEmailSearch: p.email
              }
            : x
        )
      );

      setEmailLoading(true);

      axios({
        method: "POST",
        data: {
          email
        },
        url: `${process.env.REACT_APP_API_URL}/payee-email-check`,
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY
        }
      }).then(function(res) {
        if (res.data) {
          setPayee(currentPayee =>
            currentPayee.map(x =>
              x.email === p.email
                ? {
                    ...x,
                    updatedEmail: res.data
                  }
                : x
            )
          );
        }
        setEmailLoading(false);
      });
    }
  }

  function updateEmail(email: any, id: any) {
    setPayee(currentPayee =>
      currentPayee.map(x =>
        x.id === id
          ? {
              ...x,
              email: email,
              updatedEmail: "",
              lastEmailSearch: email
            }
          : x
      )
    );

    setEmailLoading(true);

    axios({
      method: "POST",
      data: {
        email
      },
      url: `${process.env.REACT_APP_API_URL}/payee-email-check`,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY
      }
    }).then(function(res) {
      if (res.data) {
        setPayee(currentPayee =>
          currentPayee.map(x =>
            x.email === email
              ? {
                  ...x,
                  updatedEmail: res.data
                }
              : x
          )
        );
      }
      setEmailLoading(false);
    });
  }

  return (
    <Fragment>
      {!loadSuccess ? (
        <div className="spinner">
          <div className="loadingio-spinner-rolling-75vipoblcv5">
            <div className="ldio-949b26oganr">
              <div></div>
            </div>
          </div>
        </div>
      ) : formData.shoot_name === null ||
        formData.shoot_id === null ||
        formData.filmmaker_id === null ||
        formData.clip_id === null ||
        formData.clipsSelected === null ||
        formData.clips_in_collection === null ||
        loadError ? (
        <div className="all">
          <div className="main-block">
            <div className="page-not-found-title">{loadError ? "Inaccurate Data" : "Missing Data"}</div>
            <div className="description">
              Please navigate back to your{" "}
              <a
                className="white-link"
                href="https://www.filmsupply.com/dashboard/footage-manager"
                rel="noopener noreferrer"
              >
                Footage Manager
              </a>{" "}
              and try to set up royalty splits again. If this error persists, please contact{" "}
              <a className="white-link" href="mailto:content@filmsupply.com" rel="noopener noreferrer">
                content@filmsupply.com
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className="all">
          <div className={formSuccess ? "container-green" : confirmation ? "container-yellow" : "container"}>
            {formSuccess && (
              <div className="success-header">
                <div className="check-mark-container">
                  <img src={check} alt="check-mark" />
                </div>
              </div>
            )}
            <div className="form-title">
              {formSuccess ? "Received!" : confirmation ? "Are you sure?" : "Royalty Confirmation"}
            </div>

            {confirmation && (
              <div className="confirmation-container">
                <div className="confirmation-text">
                  Please <span style={{ fontWeight: 600 }}>double check and confirm</span> that all the information
                  below is correct. If any recipient emails are incorrect, money might go into the wrong accounts.
                </div>
              </div>
            )}
            {formSuccess && (
              <div className="success-container">
                <div className="success-text">
                  We have received the following royalty splits for your selected clips
                </div>
              </div>
            )}
            <form>
              <div className="filmmaker-element">{filmmaker.profileName}</div>
              <div className="header-element">{collectionName}</div>

              <div className="header-element">
                Clips Selected: {clipsSelected}/{clipsInCollection}
              </div>

              <div className="header-element">Clips IDs: {clipId}</div>
              <div className="royalty-recepients">Royalty Recipients</div>
              {confirmation || formSuccess ? (
                <div className="filmmaker-confirmation">
                  <div>
                    <span className="payee-name">
                      {admin.firstName} {admin.lastName}
                    </span>{" "}
                    ({admin.email})
                  </div>
                  <div>{formData.filmmaker_percentage}%</div>
                </div>
              ) : filmmakerInput ? (
                <div className="filmmaker-default" onClick={updateFilmmakerInput}>
                  <div>
                    <span className="payee-name">
                      {admin.firstName} {admin.lastName}
                    </span>{" "}
                    ({admin.email})
                  </div>
                  <div className={percentageTotal !== 100 ? "percentage-error" : ""}>
                    {formData.filmmaker_percentage}%
                  </div>
                </div>
              ) : (
                !filmmakerInput && (
                  <div className={filmmakerValidation ? "filmmaker-royalty" : "filmmaker-royalty error"}>
                    <div className="row-1-filmmaker">
                      <div>
                        <span className="admin-name">{admin.firstName + " " + admin.lastName}</span> ({admin.email})
                      </div>
                      <input
                        placeholder={
                          formData.filmmaker_percentage === "" || formData.filmmaker_percentage === null
                            ? "Royalty %"
                            : ""
                        }
                        value={formData.filmmaker_percentage || ""}
                        onBlur={e => {
                          if (e.target.value !== "" && e.target.value.length > 3) {
                            const percentageValue = parseFloat(e.target.value).toFixed(2);
                            setFormData({ ...formData, filmmaker_percentage: percentageValue });
                          }
                        }}
                        className={
                          formData.filmmaker_percentage === null ||
                          formData.filmmaker_percentage === "" ||
                          formData.filmmaker_percentage === "100" ||
                          parseFloat(formData.filmmaker_percentage) >= 100
                            ? "filmmaker-input error"
                            : "filmmaker-input correct"
                        }
                        name="filmmaker_percentage"
                        onChange={handleChange}
                        type="number"
                      />
                    </div>
                    <div className="row-2-filmmaker">
                      <button
                        type="button"
                        className={
                          formData.filmmaker_percentage === null ||
                          formData.filmmaker_percentage === "" ||
                          parseFloat(formData.filmmaker_percentage) >= 100
                            ? "save-filmmaker-stale"
                            : "save-filmmaker"
                        }
                        disabled={
                          formData.filmmaker_percentage === null ||
                          formData.filmmaker_percentage === "" ||
                          parseFloat(formData.filmmaker_percentage) >= 100
                        }
                        onClick={updateFilmmakerInput}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )
              )}

              {payee.map(p => {
                return (
                  <div key={p.id}>
                    {confirmation || formSuccess ? (
                      <div className="payee-confirmation">
                        <div className="payee-confirmation-row-1">
                          <div className="payee-info">
                            <div className="payee-name">
                              <span style={{ fontWeight: 600 }}>
                                {p.firstName} {p.lastName}{" "}
                              </span>
                              <span style={{ fontWeight: 400 }}>({p.email})</span>
                            </div>
                            {p.new && confirmation && (
                              <div className="warning-container">
                                <img
                                  src={warning}
                                  alt="warning"
                                  className="warning"
                                  onClick={e => {
                                    setPayee(currentPayee =>
                                      currentPayee.map(x =>
                                        x.id === p.id
                                          ? {
                                              ...x,
                                              warning: !x.warning
                                            }
                                          : x
                                      )
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </div>

                          <div>{p.percentageValue}%</div>
                        </div>
                        <div>
                          {p.warning && (
                            <div className="row-2-payee">
                              <div>
                                This email is not associated with an existing royalty recipient. If this does not sound
                                right, please verify with the recipient that this is the correct email associated with
                                their Filmsupply Account.
                                <br />
                                <br />
                                If they are new to Filmsupply, we will contact this email to help set up their account.
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : p.save ? (
                      <div
                        className="payee-default"
                        onClick={e => {
                          setPayee(currentPayee =>
                            currentPayee.map(x =>
                              x.id === p.id
                                ? {
                                    ...x,
                                    save: false,
                                    load: false
                                  }
                                : x
                            )
                          );
                        }}
                      >
                        {!p.load ? (
                          <div className="spinner-individual" />
                        ) : (
                          <Fragment>
                            <div className="row-1-payee">
                              <div>
                                <span className="payee-name">
                                  {p.firstName} {p.lastName}
                                </span>{" "}
                                ({p.email})
                              </div>
                              <div className={percentageTotal !== 100 ? "percentage-error" : ""}>
                                {p.percentageValue}%
                              </div>
                            </div>

                            {p.new ? (
                              <div className="row-2-payee">
                                <div>
                                  This email is not associated with an existing royalty recipient. If this does not
                                  sound right, please verify with the recipient that this is the correct email
                                  associated with their Filmsupply Account.
                                  <br />
                                  <br />
                                  If they are new to Filmsupply, we will contact this email to help set up their
                                  account.
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </Fragment>
                        )}
                      </div>
                    ) : (
                      !p.save && (
                        <div
                          className={
                            payee.filter(
                              x =>
                                x.id === p.id &&
                                (x.firstName.length < 2 ||
                                  x.lastName.length < 2 ||
                                  x.email === "" ||
                                  !validator.validate(x.email) ||
                                  x.percentageValue === null ||
                                  x.percentageValue === "" ||
                                  x.percentageValue > 100)
                            ).length > 0
                              ? "individual-royalty"
                              : "individual-royalty error"
                          }
                        >
                          <div className="row-1">
                            <input
                              className={
                                payee.filter(x => x.id === p.id && x.firstName.length < 2).length > 0
                                  ? "first-name error"
                                  : "first-name correct"
                              }
                              onBlur={e => {
                                emailFunction(e, p);
                              }}
                              placeholder={p.firstName === "" ? "First Name" : ""}
                              defaultValue={p.firstName === "" ? "" : p.firstName}
                              onChange={e => {
                                const firstName = e.target.value;
                                setPayee(currentPayee =>
                                  currentPayee.map(x =>
                                    x.id === p.id
                                      ? {
                                          ...x,
                                          firstName
                                        }
                                      : x
                                  )
                                );
                              }}
                            />
                            <input
                              className={
                                payee.filter(x => x.id === p.id && x.lastName.length < 2).length > 0
                                  ? "last-name error"
                                  : "last-name correct"
                              }
                              onBlur={e => {
                                emailFunction(e, p);
                              }}
                              placeholder={p.lastName === "" ? "Last Name" : ""}
                              defaultValue={p.lastName === "" ? "" : p.lastName}
                              onChange={e => {
                                const lastName = e.target.value;
                                setPayee(currentPayee =>
                                  currentPayee.map(x =>
                                    x.id === p.id
                                      ? {
                                          ...x,
                                          lastName
                                        }
                                      : x
                                  )
                                );
                              }}
                            />
                          </div>
                          <div className="row-2">
                            <input
                              id="payee-email"
                              className={
                                payee.filter(x => x.id === p.id && (x.email === "" || !validator.validate(x.email)))
                                  .length > 0
                                  ? "email error"
                                  : payee.filter(x => x.id === p.id && x.updatedEmail !== "").length > 0
                                  ? "email warning"
                                  : "email correct"
                              }
                              placeholder={p.email === "" ? "Email (Filmsupply User Account)" : ""}
                              value={p.email === "" ? "" : p.email}
                              onChange={e => {
                                const email = e.target.value.toLowerCase();
                                e.target.value = email;
                                setPayee(currentPayee =>
                                  currentPayee.map(x =>
                                    x.id === p.id
                                      ? {
                                          ...x,
                                          email
                                        }
                                      : x
                                  )
                                );
                              }}
                              // onKeyPress={lowercase}
                              onBlur={e => {
                                emailFunction(e, p);
                              }}
                            />
                          </div>
                          <div className="row-3">
                            <div className="row-3-mobile">
                              <input
                                className={
                                  payee.filter(
                                    x =>
                                      x.id === p.id &&
                                      (x.percentageValue === null ||
                                        x.percentageValue === "" ||
                                        parseFloat(x.percentageValue) > 100)
                                  ).length > 0
                                    ? "percentage error"
                                    : "percentage correct"
                                }
                                placeholder={p.percentageValue === "" || p.percentageValue === null ? "Royalty %" : ""}
                                type="number"
                                onChange={e => {
                                  const percentageValue = e.target.value;
                                  setPayee(currentPayee =>
                                    currentPayee.map(x =>
                                      x.id === p.id
                                        ? {
                                            ...x,
                                            percentageValue
                                          }
                                        : x
                                    )
                                  );
                                }}
                                value={p.percentageValue === "" || p.percentageValue === null ? "" : p.percentageValue}
                                onBlur={e => {
                                  emailFunction(e, p);
                                  if (e.target.value !== "" && e.target.value.length > 3) {
                                    const percentageValue = parseFloat(e.target.value).toFixed(2);
                                    setPayee(currentPayee =>
                                      currentPayee.map(x =>
                                        x.id === p.id
                                          ? {
                                              ...x,
                                              percentageValue
                                            }
                                          : x
                                      )
                                    );
                                  }
                                }}
                              />
                              <button
                                type="button"
                                className="delete-royalty-mobile"
                                onClick={() => {
                                  const newList = payee.filter(x => x.id !== p.id);
                                  setPayee(newList);
                                }}
                              >
                                <div className="trash-container">
                                  <img src={trash} alt="trash" className="trash" />
                                </div>
                              </button>
                            </div>
                            <button
                              type="button"
                              className={
                                payee.filter(
                                  x =>
                                    x.id === p.id &&
                                    (x.firstName.length < 2 ||
                                      x.lastName.length < 2 ||
                                      x.email === "" ||
                                      !validator.validate(x.email) ||
                                      x.percentageValue === null ||
                                      x.percentageValue === "" ||
                                      x.percentageValue > 100)
                                ).length > 0 || new Set(payee.map(item => item.email)).size !== payee.length
                                  ? "save-payee-stale"
                                  : "save-payee"
                              }
                              disabled={
                                payee.filter(
                                  x =>
                                    x.id === p.id &&
                                    (x.firstName.length < 2 ||
                                      x.lastName.length < 2 ||
                                      x.email === "" ||
                                      !validator.validate(x.email) ||
                                      x.percentageValue === null ||
                                      x.percentageValue === "" ||
                                      x.percentageValue > 100)
                                ).length > 0
                                  ? true
                                  : false
                              }
                              onClick={e => {
                                setPayee(currentPayee =>
                                  currentPayee.map(x =>
                                    x.id === p.id
                                      ? {
                                          ...x,
                                          save: true,
                                          updatedEmail: ""
                                        }
                                      : x
                                  )
                                );
                                const currentPayeeFilter = payee.filter(x => x.id === p.id);
                                findPayee(currentPayeeFilter);
                              }}
                            >
                              Save
                            </button>
                            <button
                              type="button"
                              className="delete-royalty"
                              onClick={() => {
                                const newList = payee.filter(x => x.id !== p.id);
                                setPayee(newList);
                              }}
                            >
                              <div className="trash-container">
                                <img src={trash} alt="trash" className="trash" />
                              </div>
                            </button>
                          </div>
                          {emailLoading && <div className="spinner-individual spinner-top" />}
                          {p.updatedEmail && (
                            <div className="update-email-error">
                              <div className="update-email-error-text">
                                Did you mean{" "}
                                <span className="update-email" onClick={() => updateEmail(p.updatedEmail, p.id)}>
                                  <strong>{p.updatedEmail}</strong>
                                </span>
                                ? Our records indicate this user prefers this email for payouts.
                              </div>
                            </div>
                          )}
                        </div>
                      )
                    )}
                  </div>
                );
              })}

              {!confirmation && !formSuccess && (
                <button
                  className={payee.length < 1 || payee === undefined ? "add-royalty error" : "add-royalty"}
                  type="button"
                  onClick={() => {
                    setPayee(currentPayee => [
                      ...currentPayee,
                      {
                        id: generate(),
                        firstName: "",
                        lastName: "",
                        email: "",
                        save: false,
                        percentageValue: null,
                        new: false,
                        load: false,
                        warning: false,
                        updatedEmail: "",
                        lastEmailSearch: ""
                      }
                    ]);
                  }}
                >
                  + Add Royalty Split
                </button>
              )}
              {confirmation && (
                <div className="legal">
                  By continuing, you acknowledge and agree that the information above is accurate and that this
                  information will be used to distribute royalties to the selected clips. Any previous royalties that
                  have been applied to these clips will be overwritten. This will be effective immediately for the
                  current payout period and future payouts. Any revenue from previous payouts will not be redistributed.
                </div>
              )}
              {!formSuccess && (
                <div className="nav-buttons">
                  <button
                    className="back-to-footage-manager"
                    type="button"
                    onClick={!confirmation ? backToFootageManager : editRoyalties}
                  >
                    {!confirmation ? (
                      <div className="regular-footage-manager">
                        <div className="back-arrow-container">
                          <img src={backArrow} alt="back-arrow" />
                        </div>
                        <div>Footage Manager</div>
                      </div>
                    ) : (
                      "Edit"
                    )}
                  </button>
                  <button
                    className={formValidation ? "submit-stale" : "submit"}
                    type="button"
                    value="Save and Confirm"
                    disabled={formValidation}
                    onClick={!confirmation ? setConfirmation : submitRoyalties}
                  >
                    {submission && !formSuccess ? <div className="spinner-submit" /> : "Save and Confirm"}
                  </button>
                </div>
              )}
              {formSuccess && (
                <Fragment>
                  <div className="important-container">
                    <div style={{ fontWeight: 600 }} className="important-header">
                      Important
                    </div>
                    <div>
                      Our team will now review the information you submitted and manually activate the selected clips,
                      at which time you will be notified.{" "}
                    </div>
                    <br />
                    {payee.filter(e => e.new === true).length > 0 && (
                      <Fragment>
                        <div>
                          Because one or more royalty recipients are not currently set up to receive royalties, it may
                          take additional time before the selected clips can be activated.
                        </div>
                        <br />
                        <div>
                          We have contacted the appropriate recipients to begin setting them up to receive royalties.
                          Once everyone is set up, our team will manually apply the royalties and activate the selected
                          clips. No further action is required by you. If any recipient becomes unresponsive, we will
                          notify you.
                        </div>
                      </Fragment>
                    )}
                  </div>
                  <button type="button" onClick={backToFootageManager} className="footage-manager">
                    <div className="back-arrow-container">
                      <img src={backArrow} alt="back-arrow" />
                    </div>
                    <div>Footage Manager</div>
                  </button>
                </Fragment>
              )}
              <div className={formValidation ? "error-section" : "none"}>
                {/* {(formData.filmmaker_percentage === null ||
                  formData.filmmaker_percentage === "") && (
                  <li className="list-error">
                    Royalty percentage is missing for the filmmaker admin
                  </li>
                )} */}
                {/* {!filmmakerValidation && !filmmakerInput && (
                  <li className="list-error">
                    The filmmaker admin has not been saved
                  </li>
                )} */}
                {parseFloat(formData.filmmaker_percentage) >= 100 && (
                  <li className="list-error">Filmmaker's percentage cannot be greater than or equal to 100%</li>
                )}
                {/* {(payee.length < 1 || payee === undefined) && (
                  <li className="list-error">
                    Must set up at least one royalty split
                  </li>
                )} */}
                {/* {payee.filter((e) => e.firstName.length < 2).length > 0 && (
                  <li className="list-error">
                    A payee is missing their first name
                  </li>
                )}
                {payee.filter((e) => e.lastName.length < 2).length > 0 && (
                  <li className="list-error">
                    A payee is missing their last name
                  </li>
                )}
                {payee.filter(
                  (e) => e.email === "" || !validator.validate(e.email)
                ).length > 0 && (
                  <li className="list-error">A payee is missing their email</li>
                )}
                {payee.filter(
                  (e) => e.percentageValue === null || e.percentageValue === ""
                ).length > 0 && (
                  <li className="list-error">
                    A payee is missing thier royalty percentage
                  </li>
                )} */}
                {payee.filter(e => e.percentageValue > 100).length > 0 && (
                  <li className="list-error">A collaborator's percentage cannot be greater than 100%</li>
                )}

                {new Set(payee.map(item => item.email)).size !== payee.length && (
                  <li className="list-error">You have duplicate emails</li>
                )}

                {/* {!payeeValidation &&
                  payee.filter((e) => e.save !== true).length > 0 && (
                    <li className="list-error">A payee has not been saved</li>
                  )} */}
                {((formData.filmmaker_percentage !== null && formData.filmmaker_percentage !== "") ||
                  payee.filter(e => e.percentageValue !== null && e.percentageValue !== "").length > 0) &&
                  percentageTotal !== 100 && <li className="list-error">Percentages do not equal 100%</li>}
              </div>
            </form>
            {/* {formSuccess && <div>Success!</div>} */}
          </div>
        </div>
      )}
    </Fragment>
  );
}
