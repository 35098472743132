export const humanFileSize = (bytes: number, dp: number = 1): string => {
  const threshhold = 1000;

  if (Math.abs(bytes) < threshhold) {
    return bytes + ' B';
  }

  const units = ['kB', 'MB', 'GB', 'TB', 'PB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= threshhold;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= threshhold && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}

export const humanTime = (milliseconds: number): string => {
  const ms = milliseconds % 1000;

  milliseconds = ( milliseconds - ms ) / 1000;
  const seconds = milliseconds % 60 || 0;

  milliseconds = ( milliseconds - seconds ) / 60;
  const minutes = milliseconds % 60;

  const hours = ( milliseconds - minutes ) / 60;

  let timeString = '';

  if (hours) {
    timeString += hours + ' hr ';
  }

  if (minutes) {
    timeString += minutes + ' min ';
  }

  if (!hours ) {
    timeString += seconds + ' sec';
  }

  return timeString;
}

export const formatFilename = (filename: string): string => {
  return filename
    .replace(/^.*(\\|\/|\:)/, '') // Remove path, if it exists
    .replace(/[^A-Za-z0-9\-\.]+/g, '_') // Remove non-alphanumberic
    .replace(/([\-_]{2,})/g, '_') // Clean up dashes and underscores
    .replace(/[\-_]\.|\.[\-_]/g, '.') // Clean up dots
    .replace(/^\./, ''); // No hidden files!
}

export const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const throttle = (func: Function, delay: number) => {
  let lastRun    = 0;
  let currentRun = null;

  async function throttled(...args) {
    if (currentRun) return currentRun;

    const currentWait = lastRun + delay - Date.now();
    const shouldRun   = currentWait <= 0;

    if (shouldRun) {
      lastRun     = Date.now();
      currentRun  = func(...args);
      const value = await currentRun;
      currentRun  = null;
      return value;
    } else {
      return await new Promise(function(resolve) {
        setTimeout(function() {
          resolve(throttled(...args));
        }, currentWait);
      });
    }
  }

  return throttled;
}