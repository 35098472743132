import React from "react";

export const ValidationMessage = ({message}) => (
  <span style={{color: 'red'}}>{message}</span>
);

export const RequiredMessage = () => (
  <ValidationMessage message="This field is required" />
)

export const GenericMessage = ({ message }) => (
  <ValidationMessage message={message} />
)