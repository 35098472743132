import React from "react";
import styles from "./loading.module.scss";

const Loading = () => {
  return (
    <>
      <div className={styles.loadingWrapper}>
        <div className={styles.ldsRing}><div></div><div></div><div></div><div></div></div>
      </div>
    </>
  );
}

export default Loading;

