import React from "react";
import PropTypes from 'prop-types';
import { ReleaseType } from "../types";
import check from "images/check.svg";

interface IProps {
  release: ReleaseType
}

export default function SuccessMessage({release}: IProps) {
  return (
    <div className="all-revenue">
      <div className="success-section-profile">
        <div className="check-mark-container">
          <img src={check} alt="check-mark" className="check-mark-revenue" />
        </div>
        <div className="success-title-revenue">
          {release === "talent" ? "Talent" : release === "property" ? "Property" : ""} Release Sent!
        </div>
        <div className="success-text-revenue">
          The producer will receive a confirmation email shortly, and the final{" "}
          {release === "talent" ? "talent" : "property"} release will be emailed to
          the producer once the {release === "talent" ? "talent" : "property owner"} signs it.
        </div>
      </div>
    </div>
  )
}

SuccessMessage.propTypes = {
  release: PropTypes.oneOf(['talent', 'property']).isRequired
};