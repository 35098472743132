import React, { useState } from "react";
import shortid from "shortid";
import { type UploaderFile, UploadStatus, type RejectedFile } from "../types";
import type { FileError, FileRejection, FileWithPath } from "react-dropzone";

export const useFiles = () => {
  const [files, setFiles] = useState<UploaderFile[]>([]);

  const isProcessing = files
    .filter((file) => file.status === UploadStatus.Processing).length > 0;

  const handleDrop = (acceptedFiles: FileWithPath[], fileRejections: FileRejection[]) => {
    let newFiles = [];
    let rejectedFiles = [];
    
    if (acceptedFiles && acceptedFiles.length > 0) {
      newFiles = Array.from(acceptedFiles)
        .map((acceptedFile: FileWithPath) => {
          return {
            id: shortid(),
            file: acceptedFile,
            status: UploadStatus.Queued
          } as UploaderFile
        });
    }    

    if (fileRejections && fileRejections.length > 0) {
      rejectedFiles = Array.from(fileRejections)
        .map((rejectedFile: FileRejection) => {
          return {
            id: shortid(),
            file: rejectedFile.file,
            error: rejectedFile.errors.map((error: FileError) => error.message).join(", "),
          } as RejectedFile
        });
    }

    setFiles((prevFiles) => [...prevFiles, ...newFiles, ...rejectedFiles]);
  };

  const processNext = () => {
    if (isProcessing) return;

    let found = false;

    setFiles(prevFiles => prevFiles.map(file => {
      if (!found && file.status === UploadStatus.Queued) {
        file.status = UploadStatus.Processing;

        found = true;

        return file;
      }

      return file;
    }));
  }

  const markUploaded = ((id: string) => {
    updateFileStatus(id, UploadStatus.Success);
  });

  const markFailed = ((id: string) => {
    updateFileStatus(id, UploadStatus.Error);
  });

  const markAborted = ((id: string) => {
    updateFileStatus(id, UploadStatus.Aborted);
  });

  const updateFileStatus = ((id: string, newStatus: UploadStatus) => {
    let found = false;

    setFiles(prevFiles => prevFiles.map((file) => {
      if (!found && file.id === id) {
        found = true;

        return {
          ...file,
          status: newStatus
        };
      }

      return file;
    }));
  });

  const removeFile = (id: string) => {
    setFiles(prevFiles => prevFiles.filter((entry: UploaderFile) => entry.id !== id));
  }

  return {
    files,
    isProcessing,
    handleDrop,
    processNext,
    removeFile,
    markUploaded,
    markFailed,
    markAborted
  }
}