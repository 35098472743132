import React, { useState } from "react";

export const useTimeRemaining = () => {
  const [timeMeasurements, setTimeMeasurements] = useState<number[]>([]);

  /**
     * We use linear regression (line of best fit) to stabilize
     * the time remaining. The formula was adapted from
     * @see https://github.com/Tom-Alexander/regression-js/blob/master/src/regression.js#L44
     * @params startedAt
     * @param sent 
     * @param total 
     * @returns 
     */
  const updateTimeRemaining = (startedAt: number, sent: number, total: number): number => {
    const diff = new Date().getTime() - startedAt;
    const timeRemaining = diff / (sent / total) - diff || 0;

    setTimeMeasurements((prev) => prev.concat(timeRemaining));

    // Only keep the last 30 estimates
    if (timeMeasurements.length > 30) {
        setTimeMeasurements((prev) => prev.slice(1));
    }

    const sum = [0, 0, 0, 0];
    let n = 0;

    for (; n < timeMeasurements.length; n++) {
        sum[0] += n;
        sum[1] += timeMeasurements[n];
        sum[2] += n * n;
        sum[3] += n * timeMeasurements[n];
    }

    const gradient = (n * sum[3] - sum[0] * sum[1]) / (n * sum[2] - sum[0] * sum[0]);
    const intercept = (sum[1] / n) - (gradient * sum[0]) / n;
    const value = ( n - 1 ) * gradient + intercept || timeRemaining;

    return value > -1 ? value : 0;
  }

  return {
    updateTimeRemaining
  }
};