import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import App from "./App";

const isLocal = process.env.NODE_ENV === 'development';

!!process.env.REACT_APP_SENTRY_DSN && Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  debug: isLocal,
  environment: process.env.REACT_APP_ENV,
  replaysSessionSampleRate: 0.5,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [],
});

if (isLocal) {
  ReactDOM.render(
    <StrictMode>
      <App />
    </StrictMode>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(
    <App />,
    document.getElementById("root")
  );
}
