import React, { useEffect, useMemo, useState } from "react";
import warning from "images/warning.svg";
import clip from "images/clip.svg";
import document from "images/document.svg";
import styles from "./uploader.module.scss";
import Timeout from "./Timeout";
import Button from "components/Button";
import Backdrop from "../components/Backdrop";
import WarningModal from "./WarningModal";
import { type ProcessCompletedParams, type UploadCompletedSignature, UploadType, type FootageType } from "../types";
import FootageUploader from "./FootageUploader";
import SignedUrlAwsUploader from "./SignedUrlAwsUploader";

interface Props {
  shootName: string;
  prefixes: Record<UploadType, string>;
  onUploadCompleted: UploadCompletedSignature;
  onProcessCompleted: (params: ProcessCompletedParams) => void;
}

const TIMEOUT_INTERVAL = 180; // seconds

const acceptedFootage = {
  'video/quicktime': ['.mov']
};

const acceptedRelease = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'application/pdf': ['.pdf'],
  'text/csv': ['.csv'],
  'text/plain': ['.txt'],
}

const chunkSize = 140 * 2 ** 20; // 140 MB (the same value of the old uploader)

export default function Uploader ({
  shootName,
  prefixes,
  onUploadCompleted,
  onProcessCompleted
}: Props) {
  const [showTimeout, setShowTimeout] = useState(false);
  const [showReleaseWarning, setShowReleaseWarning] = useState(false);
  const [clipsUploaded, setClipsUploaded] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [releasesUploaded, setReleasesUploaded] = useState(0);

  const [totalSize, setTotalSize] = useState(0);

  const continueAndSendToCuration = () => {
    setShowReleaseWarning(false);

    handleComplete(false);
  }

  const canSubmit = useMemo(() => {
    return clipsUploaded > 0;
  }, [clipsUploaded]);

  useEffect(() => {
    let timer: number;

    if (clipsUploaded > 0 && !showTimeout && !isProcessing) {
      timer = window.setTimeout(() => {
        setShowTimeout(true);
      }, TIMEOUT_INTERVAL * 1000);
    }
    
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [clipsUploaded, isProcessing, showTimeout]);

  const handleUploadCompleted = (file: File, type: UploadType) => {
    if (type === UploadType.Footage) {
      setClipsUploaded(prev => prev + 1);
      setTotalSize(prev => prev + file.size);
    } else {
      setReleasesUploaded(prev => prev + 1);
    }

    onUploadCompleted(file, type);
  }

  const handleComplete = (checkReleases: boolean) => {
    if (checkReleases && releasesUploaded === 0) {
      setShowReleaseWarning(true);

      return;
    }

    onProcessCompleted({
      clipsUploaded: clipsUploaded,
      releasesUploaded: releasesUploaded,
      clipsTotalSize: totalSize,
      sendToCuration: true
    });
  }

  useEffect(() => {
    if (clipsUploaded > 0 || releasesUploaded > 0) {
      onProcessCompleted({
        clipsUploaded: clipsUploaded,
        releasesUploaded: releasesUploaded,
        clipsTotalSize: totalSize,
        sendToCuration: false
      });
    }    
  }, [clipsUploaded, onProcessCompleted, releasesUploaded, totalSize])

  return (
    <>
      {showTimeout && (
        <Timeout
          onDismiss={() => setShowTimeout(false)}
          onConfirm={handleComplete}
        />
      )}

      <div className={styles.banner}>
        <img src={warning} alt="warning" height={16} width={16} draggable="false" />&nbsp;
        Keep your browser active to maximize the upload speed.
      </div>

      <div className={styles.uploadSection}>
        <h2 className={styles.title}>Uploading assets for <b>"{shootName}"</b></h2>
        <p><b>Footage Upload Requirements</b>:</p>
        <ul>
          <li>Minimum of 1080px high</li>
          <li>Files must be trimmed to less than 30 seconds (unless approved)</li>
          <li>Export at the highest resolution</li>
          <li>Signed talent releases required for every identifiable person in every shot</li>
          <li>If providing LOG, filenames must match and contain <code>-color</code> or <code>-log</code> suffix,  see details in our full footage preparation guidelines <a href="https://filmmakers.filmsupply.com/send-footage/" target="_blank" rel="noreferrer">here</a></li>          
        </ul>

        <div className={styles.uploadWrapper}>
          <div className={styles.uploadFootage}>
            <div className={styles.uploadHeader}>Upload Footage <img src={clip} alt="clip icon" height={22} draggable="false" /></div>
            <FootageUploader
              accept={acceptedFootage}
              prefix={prefixes[UploadType.Footage]}
              shouldUseMultipart={(file: File) => file.size > chunkSize}
              retries={8}
              getChunkSize={(_file: File) => chunkSize}
              onUploadCompleted={(file: File) => handleUploadCompleted(file, UploadType.Footage)}
              onIsProcessingChange={(isProcessing) => setIsProcessing(isProcessing)}
            />
          </div>
          <div style={{width: '1rem'}} />
          <div className={styles.uploadReleases}>
            <div className={styles.uploadHeader}>Upload Releases <img src={document} alt="document icon" height={22} draggable="false" /></div>
            <SignedUrlAwsUploader
              accept={acceptedRelease}
              prefix={prefixes[UploadType.Release]}
              shouldUseMultipart={(_file: File) => false}
              retries={8}
              onUploadCompleted={(file: File) => handleUploadCompleted(file, UploadType.Release)}
            />
          </div>
        </div>
        <div className={styles.submitWrapper}>
          <Button type="button" disabled={!canSubmit} onClick={() => handleComplete(true)}>
            SEND TO CURATION
          </Button>
        </div>
      </div>

      {showReleaseWarning && (
        <Backdrop>
          <WarningModal
            title="No Releases Uploaded"
            dismissText="Go Back"
            continueText="Continue and Send to Curation"
            onDismiss={() => setShowReleaseWarning(false)}
            onContinue={continueAndSendToCuration}
          >
            <p>Looks like you didn’t include any releases! If the footage does not require releases then you may proceed, otherwise please go back and upload releases.</p>
            <p>If you need to acquire releases or want to learn more about releases, visit <a href="https://filmmakers.filmsupply.com/releases" target="_blank" rel="noopener noreferrer"><b>filmmakers.filmsupply.com/releases</b></a></p>
          </WarningModal>
        </Backdrop>
      )}      
    </>
  )
}

