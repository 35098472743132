import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import "application.scss";
import "profile-info.scss";
import "talent-release.scss";
import spinner from "images/spinner.svg";
import { FormProvider, useForm, SubmitHandler } from "react-hook-form";

import { RequiredMessage } from "./components/Helpers";

import { ReleaseType, Talent, Property } from "./types";
import { TalentDescription, PropertyDescription } from "./components/Description";
import SuccessMessage from "./components/SuccessMessage";
import ProducerInfo from "./components/ProducerInfo";
import ShootInfo from "./components/ShootInfo";
import RecipientsArray from "./components/RecipientsArray";

type IFormInput = {
  filmmaker: number;
  producerFirstName: string;
  producerLastName: string;
  producerEmail: string;
  producerPhone: string,
  producerCompany: string,
  shootName: string,
  shootDate: Date,
  shootRegion: string,
  shootCountry: string,

  recipients: Array<Talent|Property>
}

export default function SendRelease(props: any) {
  const errorsRef = useRef(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serverErrors, setServerErrors] = useState<[]>([]);
  const [formSuccess, setFormSuccess] = useState<boolean | null>(false);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [release, setRelease] = useState<ReleaseType>(null);
  const [profileDataNames, setProfileDataNames] = useState<any>([]);
  
  const methods = useForm<IFormInput>({
    defaultValues: {
      filmmaker: null,
      producerFirstName: "",
      producerLastName: "",
      producerEmail: "",
      producerPhone: "",
      producerCompany: "",
      shootName: "",
      shootDate: null,
      shootRegion: "",
      shootCountry: "",
      recipients: []
    }
  });
  
  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    setServerErrors([]);

    data = Object.assign(data, {
      release: release
    });
    
    axios
      .post(`${process.env.REACT_APP_API_URL}/send-release`, data, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY }
      })
      .then(function(res: any) {
        setFormSuccess(true);
      })
      .catch(function(error: any) {
        if (Array.isArray(error.response.data)) {
          const errors = error.response.data.map((err: any) => err.message);

          setServerErrors(errors);
          errorsRef.current.scrollIntoView()  
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const { errors, dirtyFields } = methods.formState;

  const { register, setValue, getValues } = methods;

  useEffect(() => {
    document.title = "Send Release";

    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/get-profile-names`,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY
      }
    }).then(function(res) {
      setProfileDataNames(res.data);
    });
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0 || Object.keys(dirtyFields).length === 0 || getValues("recipients").length === 0) {
      setCanSubmit(false);
    } else {
      setCanSubmit(true);
    }
  }, [errors, dirtyFields, getValues]);

  const switchRelease = (release: ReleaseType) => {
    setRelease(release);
    setValue("recipients", []);
  }

  return (
    <>
      {formSuccess 
        ? <SuccessMessage release={release} />
        : (
        <div className="application-all mobile-padding">
          <div className="application-title">
            <div>Send {release === "talent" ? "Talent" : release === "property" ? "Property" : ""} Release</div>
          </div>

          <div className="application-form">
            <div className="top-options">
              <button
                className={release === "talent" ? "section-button selected" : "section-button"}
                onClick={() => switchRelease("talent")}
              >
                Talent Release
              </button>
              <button
                className={
                  release === "property" ? "section-button selected left-padding" : "section-button left-padding"
                }
                onClick={() => switchRelease("property")}
              >
                Property Release
              </button>
            </div>

            {release && (
              <FormProvider {...methods} >
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  {release === "talent" && <TalentDescription />}
                  {release === "property" &&  <PropertyDescription />}

                  {serverErrors.length > 0 && (
                    <div ref={errorsRef} style={{ backgroundColor: "#f2dede", padding: "1em", marginBottom: "10px", borderRadius: "4px"}}>
                      There are errors in the form. Please check them and submit again.

                      <ul>
                      {serverErrors.map((err, i) => (
                        <li key={i}>{err}</li>
                      ))}
                      </ul>
                    </div>
                  )}

                  <div className="application-input-section">
                    <label className="application-label" htmlFor="filmmaker">Filmsupply Profile Name</label>
                    <select
                      name="filmmaker"
                      {...register("filmmaker", { required: true })}
                      className={`input stale custom-select ${errors.filmmaker ? "application-warning" : "correct"}`}
                    >
                      <option value="">-- Select --</option>
                      {profileDataNames.map(profile => (
                        <option key={profile.id} value={profile.id}>{profile.name}</option>
                      ))}
                    </select>
                    {errors.filmmaker && <RequiredMessage />}
                  </div>

                  <ProducerInfo />

                  <ShootInfo />

                  <div className="section-title">{release === "talent" ? "Talent" : "Property"} Info</div>

                  <RecipientsArray release={release} />

                  <button
                    type="submit"
                    disabled={!canSubmit || isLoading}
                    className={(!canSubmit || isLoading) ? "application-submit-stale" : "application-submit"}
                  >
                    {isLoading
                      ? <img src={spinner} alt="check-mark" width={30} />
                      : "Send Release"
                    }
                  </button>
                </form>
              </FormProvider>
            )}
          </div>
        </div>
      )}
    </>
  );
}
