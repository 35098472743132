import axios from "axios";
import React, { useState, useEffect, createRef, Fragment } from "react";
import "./application.scss";
import "./profile-info.scss";
import check from "./images/check.svg";

export default function FilmmakerApplication(props: any) {
  const [submission, setSubmission] = useState<boolean | null>(false);
  const [formSuccess, setFormSuccess] = useState<boolean | null>(false);
  const [wordCount, setWordCount] = useState<any>("0");
  const [picName, setPicName] = useState<any>("");
  const [picSize, setPicSize] = useState<any>(0);
  const [loadSuccess, setLoadSuccess] = useState<any>(null);
  const [loadError, setLoadError] = useState<any>(null);
  const [id, setId] = useState<any>("");
  const [sizeError, setSizeError] = useState<any>(false);
  const [file, setFile] = useState<any>();
  const [profileName, setProfileName] = useState<any>("");
  const [profileBio, setProfileBio] = useState<any>("");

  useEffect(() => {
    document.title = "Profile Info";
    const params = new URLSearchParams(props.location.search);
    const idValue: any = params.get("id");

    axios({
      method: "POST",
      data: {
        filmmaker_id: idValue
      },
      url: `${process.env.REACT_APP_API_URL}/find-filmmaker-profile-info`,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY
      }
    }).then(res => {
      if (res.data === "success") {
        setLoadSuccess(true);
        setId(idValue);
      } else {
        setLoadError(res.data);
        setLoadSuccess(true);
      }
    });
  }, [props.location.search, id]);

  async function postImage({ image, profileName, profileBio }) {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("name", profileName);
    formData.append("bio", profileBio);
    formData.append("id", id);

    const result = await axios.post(`${process.env.REACT_APP_API_URL}/profile-info`, formData, {
      headers: { "Content-Type": "multipart/form-data", "x-api-key": process.env.REACT_APP_API_KEY }
    });
    return result.data;
  }

  async function submitApplication(e: any) {
    setSubmission(true);
    e.preventDefault();

    const result = await postImage({ image: file, profileName, profileBio });
    if (result) {
      setFormSuccess(true);
    }
  }

  function titleCase(e) {
    if (e.target.value) {
      var str = e.target.value.trim();

      if (str) {
        e.target.value = str
          .split(" ")
          .map((w: any) => w[0].toUpperCase() + w.substr(1).toLowerCase())
          .join(" ");
      }
    }
  }

  function wordCounter(e: any) {
    var text = e.target.value;
    var wordCount = 0;
    for (var i = 0; i <= text.length; i++) {
      if (text.charAt(i) == " " || text.charAt(i) == ".") {
        wordCount++;
      }
    }

    setWordCount(wordCount);
  }

  function stagePic(e: any) {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const name = e.target.files[0].name;
      const size = (e.target.files[0].size * 0.000001).toString();
      const newSize = parseFloat(size).toFixed(2);
      setPicName(name);
      setPicSize(newSize);
      setFile(file);
      if (e.target.files[0].size >= 50000000) {
        setSizeError(true);
      }
    }
  }

  const validation =
    profileName === "" || profileBio === "" || picName === "" || picSize === "" || wordCount > 50 || sizeError;

  return (
    <Fragment>
      {!loadSuccess ? (
        <div className="spinner">
          <div className="loadingio-spinner-rolling-75vipoblcv5">
            <div className="ldio-949b26oganr">
              <div></div>
            </div>
          </div>
        </div>
      ) : loadError ? (
        <div className="all">
          <div className="main-block">
            <div className="page-not-found-title">
              {loadError === "exists" ? "Form Already Completed" : "Inaccurate Data"}
            </div>
            <div className="description">
              {loadError === "exists" ? (
                <Fragment>
                  Our records indicate you have already completed this form. Please contact{" "}
                  <a className="white-link" href="mailto:content@filmsupply.com" rel="noopener noreferrer">
                    content@filmsupply.com
                  </a>{" "}
                  if you need to update any profile info.{" "}
                </Fragment>
              ) : (
                <Fragment>
                  Please navigate back to your{" "}
                  <a
                    className="white-link"
                    href="https://www.filmsupply.com/dashboard/footage-manager"
                    rel="noopener noreferrer"
                  >
                    Footage Manager
                  </a>{" "}
                  and try to set up royalty splits again. If this error persists, please contact{" "}
                  <a className="white-link" href="mailto:content@filmsupply.com" rel="noopener noreferrer">
                    content@filmsupply.com
                  </a>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      ) : formSuccess ? (
        <div className="all-revenue">
          <div className="success-section-profile">
            <div className="check-mark-container">
              <img src={check} alt="check-mark" className="check-mark-revenue" />
            </div>
            <div className="success-title-revenue">Received!</div>
            <div className="success-text-revenue">
              We have received your profile info and will add it to your profile soon. After we've setup your profile,
              we'll let you know the next steps.
            </div>
          </div>
        </div>
      ) : (
        <div className="application-all">
          <div className="application-title">
            <div>Profile Info</div>
          </div>
          <form onSubmit={submitApplication} className="application-form">
            <div className="application-input-section">
              <div className="application-label">Profile Name*</div>
              <input
                className={
                  profileName === "" ? "application-input-text application-warning" : "application-input-text correct"
                }
                name="profile_name"
                onChange={e => setProfileName(e.target.value)}
                onBlur={titleCase}
                value={profileName}
              />
            </div>

            <div className="application-input-section">
              <div className="application-label">Profile Picture*</div>
              <label className={sizeError ? "pic-error" : "pic-upload"}>
                <input onChange={e => stagePic(e)} type="file" accept="image/*" />
                {picName && picSize ? "Replace" : "Select"} Picture
              </label>
            </div>

            {picName && picSize && (
              <div className={sizeError ? "staged-picture-error" : "staged-picture"}>
                <div>
                  <div className="pic-name">{picName}</div>
                  <div>{picSize} MB</div>
                </div>
                <div className="checkmark-container">
                  {" "}
                  {!sizeError && <img src={check} alt="check-mark" className="check-mark-pic" />}
                </div>
              </div>
            )}

            {sizeError && <div className="size-error">Max file size is 50 MB</div>}

            <div className="application-input-section">
              <div className="application-label-bio">Profile Bio*</div>
              <textarea
                id="bio-field"
                name="profile_bio"
                className={profileBio === "" ? "bio regular" : wordCount > 50 ? "bio error" : "bio correct"}
                onChange={e => setProfileBio(e.target.value)}
                onKeyUp={e => wordCounter(e)}
                value={profileBio}
              />
              <div id="count" className="word-counter">
                {wordCount}/50
              </div>
            </div>

            <button
              type="submit"
              disabled={validation}
              className={!validation ? "application-submit" : "application-submit-stale"}
            >
              {submission && !formSuccess ? <div className="spinner-submit" /> : "Submit"}
            </button>
          </form>
        </div>
      )}
    </Fragment>
  );
}
