import React from "react";
import styles from "./success.module.scss";

interface Props {
  message: string;
}

export default function Success(props : Props) {
  return (
    <div className={styles.successContainer}>
      <p><b>{props.message}</b></p>
      <br />
      <p>Thank you! We will be contacting you shortly.</p>
    </div>
  );
}