import React from "react";
import styles from "./button.module.scss";
import { clsx } from 'clsx';

export type Variant = "base" | "gray" | "black";

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  variant?: Variant
}

const Button = (props: Props) => {
  const variant = props.variant || "base";

  return (
    <button
      type={props.type}
      disabled={props.disabled}
      className={clsx(styles.button, (variant == "gray" || props.disabled) && styles.gray, variant == "black" && styles.black)}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}

export default Button;