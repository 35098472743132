import axios from "axios";
import type { UploadType } from "../types";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 8000,
  headers: {
    "x-api-key": process.env.REACT_APP_API_KEY
  }
});

export type ResolveFilmmakerResponse = {
  filmmaker: string
}

export function resolveFilmmaker(filmmakerId: number): Promise<ResolveFilmmakerResponse> {
  return new Promise((resolve, reject) =>
    instance.get('/footage-uploader/resolve-filmmaker', {
      params: {
        filmmaker_id: filmmakerId
      },
    })
    .then(res => resolve(res.data))
    .catch(err => reject(err))
  );
}

export type MatchingFootage = {
  id: number
  contents: string
}

export type ResolveFootageResponse = Array<MatchingFootage>;

export function resolveFootage(filmmakerId: number, shoot: string): Promise<ResolveFootageResponse> {
  return new Promise((resolve, reject) =>
    instance.get('/footage-uploader/resolve-footage', {
      params: {
        filmmaker_id: filmmakerId,
        shoot: shoot
      },
    })
    .then(res => resolve(res.data))
    .catch(err => reject(err))
  );
}

export type CreateFootageResponse = {
  shoot_id: number
}

export function createFootage(body: any): Promise<CreateFootageResponse> {
  return new Promise((resolve, reject) => {
    instance.post('/footage-uploader/create-footage', body)
    .then(res => resolve(res.data))
    .catch(err => reject(err))
  });
}

export type DuplicateFootageResponse = CreateFootageResponse;

export function duplicateFootage(body: { shoot_id: number }): Promise<DuplicateFootageResponse> {
  return new Promise((resolve, reject) => {
    instance.post('/footage-uploader/duplicate-footage', body)
      .then(res => resolve(res.data))
      .catch(err => reject(err))
  });
}

export function createSidecar(shootId: number, key: string, type: UploadType): Promise<null> {
  return new Promise((resolve, reject) => {
    instance.post('/footage-uploader/create-sidecar', {
      shoot_id: shootId,
      key: key,
      upload_type: type
    })
    .then(_res => resolve(null))
    .catch(err => reject(err))
  });
}

export function finalize(body: any): Promise<any> {
  return new Promise((resolve, reject) => {
    instance.post('/footage-uploader/finalize', body)
      .then(res => resolve(res.data))
      .catch(err => reject(err))
  });
}

export type Url = string;

export type GetUploadParametersResponse = {
  method: string,
  url: Url,
  fields: Record<string, any>,
  expires: number,
}

export function getUploadParameters(filename: string, type: string, metadata?: Record<string, string>): Promise<GetUploadParametersResponse> {
  return new Promise((resolve, reject) => 
    instance.get('/s3/params', {
      params: {
        filename,
        type,
        metadata
      },
    })
    .then(res => resolve(res.data))
    .catch(err => reject(err))
  );
}

export type SignUploadResponse = {
  url: Url
}

export function signUpload(key: string): Promise<SignUploadResponse> {
  return new Promise((resolve, reject) => 
    instance.get('/s3/sign', {
      params: {
        key
      },
    })
    .then(res => resolve(res.data))
    .catch(err => reject(err))
  );
}

export type CreateMultipartUploadResponse = {
  bucket: string,
  key: string,
  uploadId: string
}

export function createMultipartUpload(filename: string, type: string, metadata?: Record<string, string>): Promise<CreateMultipartUploadResponse> {
  return new Promise((resolve, reject) =>
    instance.post('/s3/multipart', {
      filename,
      type,
      metadata
    })
    .then(res => resolve(res.data))
    .catch(err => reject(err))
  );
}

export type GetUploadedPartsResponse = {
  PartNumber?: number;
  ETag?: string;
  Size?: string;
}

export function getUploadedParts(uploadId: string, key: string): Promise<GetUploadParametersResponse> {
  return new Promise((resolve, reject) =>
    instance.get(`/s3/multipart/${uploadId}`, {
      params: {
        key
      },
    })
    .then(res => resolve(res.data))
    .catch(err => reject(err))
  );
}

export type SignPartUploadResponse = {
  url: string;
  expires: number;
}

export function signPartUpload(uploadId: string, partNumber: number, key: string): Promise<SignPartUploadResponse> {
  return new Promise((resolve, reject) => 
    instance.get(`/s3/multipart/${uploadId}/${partNumber}`, {
      params: {
        key
      },
    })
    .then(res => resolve(res.data))
    .catch(err => reject(err))
  );
}

export type Part = {
  PartNumber: number;
  ETag: string;
}

export type CompleteMultipartUploadResponse = {
  location: string;
}

export function completeMultipartUpload(uploadId: string, key: string, parts: Array<Part>): Promise<CompleteMultipartUploadResponse> {
  return new Promise((resolve, reject) => 
    instance.post(`/s3/multipart/${uploadId}/complete`, {
      parts
    }, {
      params: {
        key
      },
    })
    .then(res => resolve(res.data))
    .catch(err => reject(err))
  );
}

export function abortMultipartUpload(uploadId: string, key: string): Promise<any> {
  return new Promise((resolve, reject) => 
    instance.delete(`/s3/multipart/${uploadId}`, {
      params: {
        key
      }
    })
    .then(res => resolve(res.data))
    .catch(err => reject(err))
  );
}