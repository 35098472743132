import React from "react";

interface Props {
  errors: string[]
}

const ValidationErrors = (props: Props) => {
  return (
    <div style={{ backgroundColor: "#f2dede", padding: "1em", marginBottom: "10px", borderRadius: "4px"}}>
      There are errors in the form. Please check them and submit again.

      <ul>
      {props.errors.map((err: string, i: number) => (
        <li key={i}>{err}</li>
      ))}
      </ul>
    </div>
  );
}

export default ValidationErrors;