import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/browser";
import styles from "./footage-upload.module.scss";
import spinner from "images/spinner.svg";
import { FormProvider, useForm, type SubmitHandler } from "react-hook-form";
import { GenericMessage, RequiredMessage } from "../SendRelease/components/Helpers";
import InaccurateData from "./components/InaccurateData";
import Disclaimer from "./components/Disclaimer";
import Offline from "./components/Offline";
import useQuery from "hooks/useQuery";
import Uploader from "./components/Uploader";
import { type ProcessCompletedParams, type UploadCompletedSignature, UploadType } from "./types";
import Success from "./components/Success";
import Button from "components/Button";
import Loading from "components/Loading";
import ValidationErrors from "components/ValidationErrors";
import useNetwork from "./hooks/useNetwork";
import { type CreateFootageResponse, type DuplicateFootageResponse, createFootage, duplicateFootage, createSidecar, finalize, resolveFilmmaker, resolveFootage } from "./services/ContentEndpoints";
import { throttle } from "./utils";

const networkDetailAllowUrls = [
  window.location.origin,
];

if (process.env.R2_ACCOUNT_ID) {
  networkDetailAllowUrls.push(`${process.env.R2_ACCOUNT_ID}.r2.cloudflarestorage.com`);
}

Sentry.addIntegration(
  Sentry.replayIntegration({
    maskAllText: false,
    blockAllMedia: false,
    networkDetailAllowUrls: networkDetailAllowUrls
  })
);

enum Stages {
  Initial = 0,
  Disclaimer = 1,
  Upload = 2
}

type IFormInput = {
  shoot: string;
  isFilm: boolean;
  filmTitle: string;
  filmUrl: string;
  urlPassword: string;
}

const resolveFootageThrottled = throttle(
  (filmmakerId, value) => resolveFootage(filmmakerId, value),
  1000
);

const getFootageFolderPrefix = (filmmakerId: number, shootId: number) => {
  return `files/${filmmakerId}/${shootId}/Footage/`;
}

const getReleaseFolderPrefix = (filmmakerId: number, shootId: number) => {
  return `files/${filmmakerId}/${shootId}/_Releases/`;
}

const creationDate = new Date().toISOString();

export default function FootageUpload() {
  const query = useQuery();
  const online = useNetwork();

  const filmmakerId: number|null = query.get('filmmaker_id') ? Number.parseInt(query.get('filmmaker_id'), 10) : null;

  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);

  const [existingShootId, setExistingShootId] = useState<number>(null);
  const [shootId, setShootId] = useState<number>(null);
  const [filmmaker, setFilmmaker] = useState<string>(null);
  const [isFilmmakerLoading, setIsFilmmakerLoading] = useState(false);
  const [stage, setStage] = useState<Stages>(Stages.Initial);
  const [isLoading, setIsLoading] = useState(false);
  const [serverErrors, setServerErrors] = useState<[]>([]);
  
  const [successMessage, setSuccessMessage] = useState<string>();

  const methods = useForm<IFormInput>({
    defaultValues: {
      shoot: '',
      isFilm: false,
      filmTitle: '',
      filmUrl: '',
      urlPassword: ''
    }
  });

  const { register, setValue, getValues, watch, reset } = methods;
  
  const watchShoot = watch("shoot", "");
  const watchIsFilm = watch("isFilm", false);

  const isExistingShoot = existingShootId !== null && existingShootId !== 0;

  useEffect(() => {
    if (watchShoot.length < 3) {
      setExistingShootId(null);
      setSuggestions([]);

      return;
    };

    if (existingShootId) return;

    setSuggestionsLoading(true);

    resolveFootageThrottled(filmmakerId, watchShoot)
      .then(results => {
        setSuggestions(results);
      })
      .finally(() => {
        setSuggestionsLoading(false);
      })
  }, [filmmakerId, existingShootId, watchShoot]);

  useEffect(() => {
    document.title = "Footage Upload";

    if (filmmakerId) {
      setIsFilmmakerLoading(true);

      resolveFilmmaker(filmmakerId)
        .then((data) => {
          const { filmmaker } = data;

          setFilmmaker(filmmaker);
        })
        .catch((error: any) => {
          console.error(error);

          setFilmmaker(null);
        })
        .finally(() => {
          setIsFilmmakerLoading(false);
        }) 
    }    
  }, [filmmakerId]);

  const handleClick = (suggestion: string, id?: number) => {
    setSuggestions([]);

    if (id) {
      setExistingShootId(id);
    } else {
      setExistingShootId(0);
    }

    setValue('shoot', suggestion);
  }

  const clearTypeahead = () => {
    setValue("shoot", "");
    setSuggestions([]);
    setExistingShootId(null);
  }

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    setServerErrors([]);
    
    let promise: Promise<CreateFootageResponse|DuplicateFootageResponse>;

    if (existingShootId > 0) {
      const body: any = {
        filmmaker_id: filmmakerId,
        shoot_id: existingShootId
      };

      promise = duplicateFootage(body);
    } else {
      const body: any = {
        filmmaker_id: filmmakerId,
        shoot_name: data.shoot
      }
      
      if (data.filmTitle) {
        body.film = {
          title: data.filmTitle,
          url: data.filmUrl,
          password: data.urlPassword,
        };
      }

      promise = createFootage(body);
    } 

    promise
      .then((data) => {
        setShootId(data.shoot_id);

        setStage(Stages.Disclaimer);
      })
      .catch((error: any) => {
        if (Array.isArray(error.response.data)) {
          const errors = error.response.data.map((err: any) => err.message);

          setServerErrors(errors);

          return;
        }

        Sentry.captureException(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const handleUploadCompleted: UploadCompletedSignature = (file, type) => {
    let prefix = "";
    
    if (type === UploadType.Release) {
      prefix = getReleaseFolderPrefix(filmmakerId, shootId);
    } else if (type === UploadType.Footage) {
      prefix = getFootageFolderPrefix(filmmakerId, shootId);
    }

    createSidecar(shootId, prefix + file.name, type)
      .catch((error: any) => {
        console.log(error);

        Sentry.captureException(error);
      });
  }

  const { errors } = methods.formState;

  useEffect(() => {
    if (watchIsFilm) {
      setValue('filmTitle', '');
      setValue('filmUrl', '');
      setValue('urlPassword', '');
    }
  }, [watchIsFilm, setValue])

  const handleProcessCompleted = (params: ProcessCompletedParams) => {
    const data =  {
      filmmaker_id: filmmakerId,
      shoot_id: shootId,
      clips_count: params.clipsUploaded,
      size: params.clipsTotalSize,
      releases_count: params.releasesUploaded,
      film_title: getValues('filmTitle'),
      film_url: getValues('filmUrl'),
      film_password: getValues('urlPassword'),
      send_to_curation: params.sendToCuration,
      timestamp: creationDate
    };

    finalize(data)
      .then((_res: any) => {
        if (params.sendToCuration) {
          setSuccessMessage(`${params.clipsUploaded} clips were successfully uploaded to the shoot ${getValues('shoot')}!`);

          reset();

          setStage(Stages.Initial);
          setSuggestions([]);
          setShootId(null);
          setIsLoading(false);
        }      
      })
      .catch((error: any) => {
        console.log(error);

        Sentry.captureException(error);
      });
  };

  if (isFilmmakerLoading) {
    return <Loading />;
  }

  if (!filmmaker) {
    return (
      <div className="application-all mobile-padding">
        <InaccurateData />
      </div>
    );
  }

  return (
    <div className="application-all mobile-padding">
      {successMessage && <Success message={successMessage} />}

      {stage === Stages.Initial && (
        <>
          <div className="application-title">{filmmaker} - Upload Footage</div>
          <div className="application-form">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
                {serverErrors.length > 0 && <ValidationErrors errors={serverErrors} />}

                <div className="application-input-section">
                  <div className={styles.typeaheadContainer}>
                    <label className="application-label" htmlFor="shootName">
                      Shoot Name {suggestionsLoading && (<div className={styles.loader} />)}
                    </label>
                    <input
                      id="shootName"
                      name="shootName"
                      type="text"
                      placeholder="Search shoot by name or Create a new one"
                      {...register("shoot", {required: true, validate: value => value.length > 2 })}
                      className="application-input-text"
                      readOnly={!!existingShootId}
                    />
                    {existingShootId !== null && (
                      <button type="button" className={styles.clearButton} onClick={clearTypeahead} title="Clear">×</button>
                    )}                    
                  </div>
                  {(watchShoot.length > 2 && existingShootId === null) && (
                    <ul className={styles.suggestions}>
                      <li onClick={() => handleClick(watchShoot)}>+ Add "{watchShoot}"</li>
                      {suggestions.map((suggestion) => (
                        <li key={suggestion.id} onClick={() => handleClick(suggestion.contents, suggestion.id)}>{suggestion.contents}</li>
                      ))}
                    </ul>
                  )}
                  {errors.shoot?.type === 'required' && <RequiredMessage />}
                  {errors.shoot?.type === 'validate' && <GenericMessage message={'The input is not valid (Must be at least 3 characters)'} />}
                </div>

                <div className="application-input-section">
                  <div className="checkmark-section">
                    <label className="application-label" htmlFor="isFilm">
                      <input
                        id="isFilm"
                        name="isFilm"
                        type="checkbox"
                        disabled={isExistingShoot}
                        {...register("isFilm")}
                      />
                      This footage is from an edited film (Narrative, music video, spec ad, documentary, or experimental piece)
                    </label>
                  </div>
                </div>

                {watchIsFilm && (
                  <>
                    <div className="application-input-section">
                      <label className="application-label" htmlFor="filmTitle">Film Title</label>
                      <input
                        id="filmTitle"
                        name="filmTitle"
                        placeholder="Film Title"
                        {...register("filmTitle", {required: true})}
                        className={`application-input-text ${errors.filmTitle ? "application-warning" : "correct"}`}
                      />
                      {errors.filmTitle?.type === 'required' && <RequiredMessage />}
                      {errors.filmTitle?.type === 'validate' && "Not valid"}
                    </div>
                    <div className="application-input-section">
                      <label className="application-label" htmlFor="filmUrl">Film URL</label>
                      <input
                        id="filmUrl"
                        name="filmUrl"
                        placeholder="Film URL"
                        type="url"
                        {...register("filmUrl", {required: true})}
                        className={`application-input-text ${errors.filmUrl ? "application-warning" : "correct"}`}
                      />
                      {errors.filmUrl?.type === 'required' && <RequiredMessage />}
                      {errors.filmUrl?.type === 'validate' && "Not valid"}
                    </div>
                    <div className="application-input-section">
                      <label className="application-label" htmlFor="urlPassword">URL Password</label>
                      <input
                        id="urlPassword"
                        name="urlPassword"
                        placeholder="URL Password"
                        {...register("urlPassword", {required: false})}
                        className={`application-input-text ${errors.urlPassword ? "application-warning" : "correct"}`}
                      />
                      {errors.urlPassword?.type === 'required' && <RequiredMessage />}
                      {errors.urlPassword?.type === 'validate' && "Not valid"}
                    </div>
                  </>
                )}

                <Button disabled={isLoading} type="submit">
                  {isLoading
                    ? <img src={spinner} alt="check-mark" width={30} />
                    : "Next"
                  }
                </Button>
              </form>
            </FormProvider>
          </div>
        </>
      )}

      {stage === Stages.Disclaimer && (
        <Disclaimer onContinue={() => setStage(Stages.Upload)} />
      )}

      {(stage === Stages.Upload) && (
        <Uploader
          shootName={getValues('shoot')}
          prefixes={{
            [UploadType.Footage]: getFootageFolderPrefix(filmmakerId, shootId),
            [UploadType.Release]: getReleaseFolderPrefix(filmmakerId, shootId),
          }}
          onUploadCompleted={handleUploadCompleted}
          onProcessCompleted={handleProcessCompleted}
        />
      )}

      {!online && <Offline />}
    </div>
  );
}
