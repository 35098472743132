import axios from "axios";
import React, { useState, useEffect, Fragment } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import "./all-shoots.scss";
import ServerError from "./ServerError";
import holdImage from "./images/hold.svg";
import completedImage from "./images/completed.svg";
import deletedImage from "./images/deleted.svg";
import arrowImage from "./images/arrow.svg";

export default function AllShoots(props) {
  const [filmmaker, setFilmmaker] = useState<any>({});
  const [shoots, setShoots] = useState<any>([]);

  const [req, setReq] = useState<any>(false);
  const [error, setError] = useState<any>(false);

  useEffect(() => {
    const fetchShoots = () => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/all-shoots`, {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY
          },
          params: {
            id: props.match.params.filmmakerId
          }
        })
        .then(function(res) {
          const filmmaker = res.data.filmmaker;
          const shootData = res.data.shoots;

          setFilmmaker(filmmaker);
          setShoots(shootData);
          setReq(true);
        })
        .catch(function(err) {
          // console.log(err);
          setError(true);
        });
    };
    fetchShoots();
  }, [props.match.params.filmmakerId]);

  const newId = filmmaker.fsAdminId * 9575;

  return (
    <Fragment>
      <div key={filmmaker.id}>
        <div className="header">
          <div className="filmsupply-logo">Filmsupply</div>

          <div className="sub-header">
            <div className="footage-tracker">Footage Tracker</div>
          </div>
        </div>

        {!req && !error ? (
          <div className="spinner">
            <div className="loadingio-spinner-rolling-75vipoblcv5">
              <div className="ldio-949b26oganr">
                <div></div>
              </div>
            </div>
          </div>
        ) : error ? (
          <ServerError />
        ) : (
          <Fragment>
            <div className="top-banner">
              <div className="profile-name-header">
                <div className="profile-name">{filmmaker.profileName}</div>
              </div>
              <a
                href="mailto:content@filmsupply.com"
                rel="noopener noreferrer"
                target="_blank"
                className="contact-button"
              >
                Contact Us
              </a>
            </div>

            {!shoots.length ? (
              <div className="no-shoots-section">
                <div className="no-shoots-body">You currently don't have any shoots submitted to Filmsupply.</div>
                <a
                  href={"https://upload.filmsupply.com/?username=" + filmmaker.fsAdminId}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="no-shoot-button"
                >
                  Upload Footage
                </a>
              </div>
            ) : (
              <Fragment>
                {shoots
                  .sort(({ id: previousID }, { id: currentID }) => currentID - previousID)
                  .map(shoot => (
                    <div key={shoot.id}>
                      <Link to={`/filmmakers/${newId}/shoots/${shoot.id}`} style={{ textDecoration: "none" }}>
                        {shoot.stage !== "Combined" &&
                          shoot.stage !== "Duplicate" &&
                          shoot.hideFootageTracker !== true && (
                            <div className="wrapper">
                              <div className="shoot">
                                <div className={shoot.denialApproved ? "shoot-name-title-blank" : "shoot-name-title"}>
                                  Shoot Name
                                </div>
                                <div className={shoot.denialApproved ? "title-blank" : "title"}>{shoot.contents}</div>
                              </div>

                              <div className="stage-section">
                                {shoot.stage === "Completed" ||
                                shoot.stage === "Staff Pick" ||
                                shoot.stage === "Add to Collection" ||
                                shoot.stage === "Delete Footage" ||
                                shoot.denialApproved ? (
                                  <div></div>
                                ) : (
                                  <Fragment>
                                    <div className="stage-title">Stage</div>

                                    {shoot.stage === "Pre-Approval" ? (
                                      <div className="stage">Pre-Approval Review</div>
                                    ) : shoot.stage === "Set Up" &&
                                      shoot.reviewStatus === "Approved" &&
                                      !shoot.sendtoCuration ? (
                                      <div className="stage">Ready For Upload</div>
                                    ) : shoot.stage === "Set Up" &&
                                      !shoot.sendtoCuration &&
                                      shoot.reviewStatus !== "Approved" ? (
                                      <div className="stage">Footage Uploading</div>
                                    ) : shoot.stage === "Transfer Footage" ||
                                      (shoot.stage === "Set Up" && shoot.sendtoCuration !== null) ? (
                                      <div className="stage">Footage Received</div>
                                    ) : shoot.stage === "Curate" || shoot.stage === "Curation Review" ? (
                                      <div className="stage">Curation</div>
                                    ) : shoot.stage === "Color" || shoot.stage === "Color Review" ? (
                                      <div className="stage">Color</div>
                                    ) : shoot.stage === "Export" ||
                                      shoot.stage === "Organize" ||
                                      shoot.stage === "Sync" ||
                                      shoot.stage === "Import" ? (
                                      <div className="stage">Importing to Site</div>
                                    ) : (
                                      (shoot.stage === "Import Review" || shoot.stage === "Final Review") && (
                                        <div className="stage">Adding Metadata</div>
                                      )
                                    )}
                                  </Fragment>
                                )}
                              </div>

                              <div className="date">
                                {shoot.assetsRequested && !shoot.denialApproved ? (
                                  <div className="status-icon">
                                    <img className="hold-image" alt="on-hold" src={holdImage} />
                                    On Hold
                                    <div className="arrow">
                                      <img src={arrowImage} alt="arrow" />
                                    </div>
                                  </div>
                                ) : shoot.denialApproved ? (
                                  <div className="status-icon">
                                    <div className="denied-text">Footage Not Accepted</div>
                                    <img src={deletedImage} alt="denied" />
                                  </div>
                                ) : shoot.stage === "Completed" ||
                                  shoot.stage === "Staff Pick" ||
                                  shoot.stage === "Add to Collection" ||
                                  shoot.stage === "Delete Footage" ? (
                                  <div className="status-icon">
                                    <div className="completed-text">Available in Footage Manager</div>
                                    <img src={completedImage} alt="completed" />
                                  </div>
                                ) : (shoot.stage === "Set Up" && shoot.sendtoCuration !== true) ||
                                  shoot.stage === "Pre-Approval" ? (
                                  <div className="arrow">
                                    <img src={arrowImage} alt="arrow" />
                                  </div>
                                ) : (
                                  <div className="date-regular">
                                    <div className="date-info">
                                      <div className="date-title">Available in Footage Manager</div>
                                      <div className="date-range">
                                        <Moment format="MMMM D">{shoot.completionDate1}</Moment>
                                      </div>
                                    </div>
                                    <div className="arrow">
                                      <img src={arrowImage} alt="arrow" />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                      </Link>
                    </div>
                  ))}
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}
