import React from "react";

export function TalentDescription() {
  return (
    <div className="description-section">
      <div className="talent-description-text">
        Submitting this form will email a talent release link to talent (specified below) and a confirmation email to
        the producer (specified below).
      </div>

      <div className="talent-description-text">
        <br />
        Once talent signs their release, the producer and talent will receive an email confirmation with a link to
        download the signed release. Then, the producer can upload the release to Filmsupply.
      </div>
    </div>
  );
}

export function PropertyDescription() {
  return (
    <div className="description-section">
      <div className="talent-description-text">
        Submitting this form will email a property release link to the property owner (specified below) and a
        confirmation email to the producer (specified below).
      </div>

      <div className="talent-description-text">
        <br />
        Once the property owner signs their release, the producer and property owner will receive an email confirmation
        with a link to download the signed release. Then, the producer can upload the release to Filmsupply.
      </div>
    </div>
  );
}
