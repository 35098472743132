import React, { useState } from "react";
import PropTypes from 'prop-types';
import validator from "email-validator";
import { RequiredMessage, ValidationMessage } from "./Helpers";
import Compensation from "./Compensation";
import trash from "images/trash.svg";

export default function Talent({index, register, errors, trigger, getValues, setValue, watch, onDelete}) {
  const [isSaved, setIsSaved] = useState<boolean>(false);

  return (
    <div className={isSaved ? "saved-talent" : "talent-background"}>
      {isSaved ? (
        <div onClick={() => setIsSaved(false)}>
          <span>{getValues(`recipients.${index}.email`)}</span>
        </div>
      ) : (
        <>
          <div className="application-input-section">
            <label className="application-label">Talent/Parent/Guardian's Email</label>
            <input
              name="email"
              {...register(`recipients.${index}.email` as const, {required: true, validate: value => validator.validate(value) })}
              className={`application-input-text ${errors.email ? "application-warning" : "correct"}`}
            />
            {errors.email?.type === 'required' && <RequiredMessage />}
            {errors.email?.type === 'validate' && <ValidationMessage message="Email is not valid" />}
          </div>

          <Compensation
            recipient={`recipients.${index}`}
            errors={errors}
            release="talent"
            {...{ register, setValue, watch }}
          />

          <div className="talent-buttons">
            <button
              type="button"
              className="save-talent"
              onClick={async () => {
                const result = await trigger(`recipients.${index}`);
                
                setIsSaved(result);
              }}
            >
              Save
            </button>
            <button
              type="button"
              className="delete-talent"
              onClick={() => onDelete(index)}
            >
              <div className="trash-container">
                <img src={trash} alt="trash" className="trash" />
              </div>
            </button>
          </div>
        </>
      )}
    </div>
  );
}

Talent.propTypes = {
  index: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired
};

Talent.defaultProps = {
  errors: {}
}