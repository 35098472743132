import { useState, useReducer } from "react";
import shortid from "shortid";
import { type FootageType, type UploaderFile, UploadStatus, type RejectedFile } from "../types";
import type { FileRejection, FileWithPath } from "react-dropzone";

type FootageFile = Record<FootageType, UploaderFile>

type State = Map<string, FootageFile>

type ACTIONTYPE =
    | { type: "add_log", key: string, file: File }
    | { type: "add_color"; key: string, file: File }
    | { type: "remove", key: string, footageType: FootageType }
    | { type: "process_next" }
    | { type: "mark_failed", key: string, footageType: FootageType, error: string }
    | { type: "mark_success", key: string, footageType: FootageType }
    | { type: "clear" }

const getFilename = (name: string): string => {
  return name.substring(0, name.lastIndexOf('.')) || name;
}

const isColor = (name: string): boolean => {
  const filename = getFilename(name);

  // assume color if no suffix
  return filename.toLowerCase().endsWith("-color") || !filename.toLowerCase().endsWith("-log");
}

const isLog = (name: string):boolean => {
  const filename = getFilename(name);

  return filename.toLowerCase().endsWith("-log");
}

const extractCommonFilename = (name: string) => {
  const filename = getFilename(name);

  if (isColor(filename)) {
    const position = filename.toLowerCase().lastIndexOf("-color") > 0
      ? filename.toLowerCase().lastIndexOf("-color")
      : filename.length;

    return filename.substring(0, position);
  }

  if (isLog(filename)) {
    return filename.substring(0, filename.toLowerCase().lastIndexOf("-log"));
  }

  return filename;
}

const reducer = (state: State, action: ACTIONTYPE): State => {
  switch (action.type) {
    case "add_log": {
      let entry: FootageFile = state.get(action.key);

      if (entry === undefined) {
        entry = {
          color: null,
          log: {
            id: shortid(),
            file: action.file,
            status: UploadStatus.MissingColor
          } as UploaderFile
        };
      } else {
        entry.log = {
          id: shortid(),
          file: action.file,
          status: UploadStatus.Queued
        } as UploaderFile

        if (entry.color) {
          entry.color = {
            ...entry.color,
            status: UploadStatus.Queued
          }
        }
      }
        
      return new Map(
        state.set(action.key, entry)
      );
    }

    case "add_color": {
      let entry: FootageFile = state.get(action.key);

      if (entry === undefined) {
        entry = {
          color: {
            id: shortid(),
            file: action.file,
            status: UploadStatus.MissingLog
          } as UploaderFile,
          log: null
        };
      } else {
        entry.color = {
          id: shortid(),
          file: action.file,
          status: entry.log ? UploadStatus.Queued : UploadStatus.MissingLog
        } as UploaderFile

        if (entry.log) {
          entry.log = {
            ...entry.log,
            status: UploadStatus.Queued
          }
        }
      }

      return new Map(
        state.set(action.key, entry),
      );
    }

    case "remove": {
      const entry: FootageFile = state.get(action.key);

      if (entry === undefined) {
        return state;
      }

      entry[action.footageType] = null;

      if (entry.color === null && entry.log === null) {
        state.delete(action.key);

        return new Map(state);
      }

      if (action.footageType === "color" && entry.log) {
        entry.log = {
          ...entry.log,
          status: UploadStatus.MissingColor
        }
      }

      if (action.footageType === "log" && entry.color) {
        entry.color = {
          ...entry.color,
          status: UploadStatus.MissingLog
        }
      }

      return new Map(
        state.set(action.key, entry),
      );
    }
    
    case "process_next": {
      let key: string = null;
      let entry: FootageFile = null;

      for (const [k, value] of state) {
        if (value.color && (value.color.status === UploadStatus.MissingLog || value.color.status === UploadStatus.Queued)) {
          key = k;
          entry = value;

          value.color.status = UploadStatus.Processing;

          break;
        }

        if (value.log && value.log.status === UploadStatus.Queued) {
          key = k;
          entry = value;

          value.log.status = UploadStatus.Processing;

          break;
        }
      }

      if (!key) {
        return state;
      }

      return new Map(
        state.set(key, entry),
      );
    }

    case "mark_failed": {
      const entry: FootageFile = state.get(action.key);

      if (entry === undefined) {
        return state;
      }

      if (action.footageType === "color" && entry.color) {
        entry.color = {
          ...entry.color,
          status: UploadStatus.Error
        }
      }

      if (action.footageType === "log" && entry.log) {
        entry.log = {
          ...entry.log,
          status: UploadStatus.Error
        }
      }

      return new Map(
        state.set(action.key, entry),
      );
    }

    case "mark_success": {
      const entry: FootageFile = state.get(action.key);

      if (entry === undefined) {
        return state;
      }

      if (action.footageType === "color" && entry.color) {
        entry.color = {
          ...entry.color,
          status: UploadStatus.Success
        }
      }

      if (action.footageType === "log" && entry.log) {
        entry.log = {
          ...entry.log,
          status: UploadStatus.Success
        }
      }

      return new Map(
        state.set(action.key, entry),
      );
    }

    case "clear":
      return new Map();
  
    default:
      return state;
  }
}

export const useFootageFiles = () => {
  const [rejectedFiles, setRejectedFiles] = useState<RejectedFile[]>([]);

  const [files, dispatch] = useReducer(reducer, new Map());

  const isProcessing: boolean = Object.values(files).filter((item: FootageFile) => {
    return Object.values(item).filter((file: UploaderFile) => file.status === UploadStatus.Processing);
  }).length > 0;

  const handleDrop = (acceptedFiles: FileWithPath[], fileRejections: FileRejection[]) => {
    const rejections: RejectedFile[] = [];

    for (let index = 0; index < acceptedFiles.length; index++) {
      const element = acceptedFiles[index];
      
      const key = extractCommonFilename(element.name);
      
      if (files.has(key)) {
        if (
          files.get(key).log && isLog(element.name)
          || 
          files.get(key).color && isColor(element.name)
        ) {
          rejections.push({
            id: shortid(),
            file: element,
            error: "File already exists"
          } as RejectedFile);

          continue;
        }
      }

      if (isLog(element.name)) {
        dispatch({
          type: 'add_log',
          key: key,
          file: element,
        })
      } else if (isColor(element.name)) {
        dispatch({
          type: 'add_color',
          key: key,
          file: element,
        })
      }
    }

    for (const rejectedFile of fileRejections) {
      rejections.push({
        id: shortid(),
        file: rejectedFile.file,
        error: "File is not .mov"
      } as RejectedFile)
    }

    setRejectedFiles((prevFiles) => [...prevFiles, ...rejections]);
  };

  const processNext = () => {
    if (isProcessing) return;

    dispatch({
      type: 'process_next'
    });
  }

  const markUploaded = ((key: string, type: FootageType) => {
    dispatch({
      type: "mark_success",
      key: key,
      footageType: type,
    });
  });

  const markFailed = ((key: string, type: FootageType, error) => {
    dispatch({
      type: "mark_failed",
      key: key,
      footageType: type,
      error: error
    })
  });

  const removeRejectedFile = (id: string) => {
    setRejectedFiles(prevFiles => prevFiles.filter((entry: RejectedFile) => entry.id !== id));
  }

  const removeApprovedFile = (key: string, type: FootageType) => {
    dispatch({
      type: "remove",
      key: key,
      footageType: type
    });
  }

  const removeAllFiles = () => {
    dispatch({
      type: "clear"
    });
  }

  return {
    files,
    rejectedFiles,
    isProcessing,
    handleDrop,
    processNext,
    removeApprovedFile,
    removeRejectedFile,
    removeAllFiles,
    markUploaded,
    markFailed
  }
}