import React from "react";
import "./page-not-found.scss";

export default function PageNotFound() {
  return (
    <div className="all">
      <div className="main-block">
        <div className="page-not-found-title">Page Not Found</div>
        <div className="description">
          Please navigate back to your{" "}
          <a
            data-modified
            className="white-link"
            href="https://www.filmsupply.com/dashboard/footage-manager"
            rel="noopener noreferrer"
          >
            Footage Manager
          </a>{" "}
          and click "Footage Tracker" for the correct page.
        </div>
      </div>
    </div>
  );
}
