import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { RequiredMessage, ValidationMessage } from "./Helpers";

export default function Compensation({recipient, register, errors, setValue, watch, release}) {
  const watchRoyaltyCheckmark = watch(`${recipient}.royaltyCheckmark`);
  const watchRoyaltyCap = watch(`${recipient}.royaltyCap`);
  const subject = release === "talent" ? "Talent" : "Property Owner";
   
  useEffect(() => {
    if (watchRoyaltyCap === null || watchRoyaltyCap === '') {
      setValue(`${recipient}.royaltyPercentageAfterCap`, '');
    }
  }, [watchRoyaltyCap, recipient, setValue]);

  return (
    <>
      <div className="application-input-section">
        <label className="application-label">
          How much money is the {subject} being compensated? (US Dollars)
        </label>
        <input
          type="number"
          min="0"
          onWheel={e => e.currentTarget.blur()}
          {...register(`${recipient}.compensation` as const, { required: true, min: 0})}
          className={`application-input-text ${errors.compensation ? "application-warning" : "correct"}`}
        />
        {errors.compensation?.type === 'required' && <RequiredMessage />}
      </div>

      <div className="royalty-section">
        <div className="checkmark-section">
          <label className="application-label">
            <input
              type="checkbox"
              {...register(`${recipient}.royaltyCheckmark` as const)}
            />
            {subject} will be receiving licensing royalties
          </label>
        </div>

        {watchRoyaltyCheckmark && (
          <div className="royalty-options">
            <div className="application-input-section">
              <label className="application-label">
                Royalty Percentage
              </label>
              <input
                type="number"
                onWheel={e => e.currentTarget.blur()}
                {...register(`${recipient}.royaltyPercentage` as const, { required: true, min: 1, max: 100 })}
                className={`application-input-text ${errors.royaltyPercentage ? "application-warning" : "correct"}`}
                min="1"
                max="100"
              />
              {errors.royaltyPercentage?.type === 'required' && <RequiredMessage />}
              {errors.royaltyPercentage?.type === 'max' && <ValidationMessage message="The value must be a number between 1 and 100" />}
            </div>

            <div className="application-input-section">
              <label className="application-label">
                Royalty Cap in USD (optional)
              </label>
              <small className="block">The net revenue a {subject} will be limited to in Royalty Payments</small>
              <input
                type="number"
                onWheel={e => e.currentTarget.blur()}
                {...register(`${recipient}.royaltyCap` as const, { min: 1})}
                className={`application-input-text ${errors.royaltyCap ? "application-warning" : "correct"}`}
              />                  
            </div>

            <div className="application-input-section">
              <label className="application-label">
                Royalty Percentage After Cap Is Met
              </label>
              <input
                type="number"
                onWheel={e => e.currentTarget.blur()}
                {...register(`${recipient}.royaltyPercentageAfterCap` as const, {required: watchRoyaltyCap, min: 0, max: 100})}
                className={`application-input-text ${errors.royaltyPercentageAfterCap ? "application-warning" : "correct"}`}
                min="0"
                max="100"
                disabled={watchRoyaltyCap === null || watchRoyaltyCap === ''}
              />
              {errors.royaltyPercentageAfterCap?.type === 'required' && <RequiredMessage />}
              {errors.royaltyPercentageAfterCap?.type === 'max' && <ValidationMessage message="The value must be a number between 1 and 100" />}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

Compensation.propTypes = {
  recipient: PropTypes.string.isRequired,
  release: PropTypes.oneOf(["talent", "property"]).isRequired
};