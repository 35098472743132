import React from "react";

import styles from './offline.module.scss';

export default function Offline() {
  return (
    <div className={styles.offline}>
      You're offline! Check your connection.
    </div>
  )
}