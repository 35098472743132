import React, { type ReactNode } from "react";
import Button from "components/Button";
import warning from "images/warning.svg";
import styles from "./warning-modal.module.scss";

interface Props {
  title?: string
  children: ReactNode
  dismissText?: string
  onDismiss?: () => void
  continueText?: string
  onContinue?: () => void
}

export default function ReleaseWarning(props : Props) {
  return (
    <div className={styles.warning}>
      <header>
        <img
          src={warning}
          alt="warning"
        />
        {props.title || "Warning"}
      </header>

      <div className={styles.warningBody}>
        {props.children}
      </div>

      <div className={styles.warningFooter}>
        {props.onDismiss && (
          <Button
            variant="black"
            onClick={props.onDismiss}
          >
            {props.dismissText || "Dismiss"}
          </Button>
        )}
        &nbsp;
        {props.onContinue && (
          <Button
            variant="black"
            onClick={props.onContinue}
          >
            {props.continueText || "Continue"}
          </Button>
        )}
      </div>
    </div>
  );
}