import React from "react";

interface Props {
  children?: React.ReactNode
}

const style = {
  backgroundColor: '#000000AF',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
} as React.CSSProperties;

const center = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
} as React.CSSProperties;

const Backdrop = (props: Props) => {
  return (
    <div style={style}>
      <div style={center}>{props.children}</div>
    </div>
  );
}

export default Backdrop;