import axios from "axios";
import React, { useState, useEffect, Fragment } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import "./individual-shoot.scss";
import holdImage from "./images/hold.svg";
import completedImage from "./images/completed.svg";
import deniedBlack from "./images/denied-black.svg";
import arrowImage from "./images/arrow.svg";

export default function IndividualShoot(props) {
  const [filmmaker, setFilmmaker] = useState<any>({});
  const [shoot, setShoot] = useState<any>([]);

  const [req, setReq] = useState<any>(false);
  const [error, setError] = useState<any>(false);

  useEffect(() => {
    const fetchShoot = () => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/individual-shoot`, {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY
          },
          params: {
            filmmakerId: props.match.params.filmmakerId,
            shootId: props.match.params.shootId
          }
        })
        .then(function(res) {
          if (res.data.shoot === null || res.data.filmmaker === null || res.data === "") {
            setError(true);
            setReq(true);
          } else {
            const filmmaker = res.data.filmmaker;
            const shoot = res.data.shoot;

            setFilmmaker(filmmaker);
            setShoot(shoot);
            setReq(true);
          }
        })
        .catch(function(err) {
          setError(true);
          setReq(true);
        });
    };

    fetchShoot();
  }, [props.match.params.filmmakerId, props.match.params.shootId]);

  return (
    <Fragment>
      <div className="header">
        <div className="filmsupply-logo">Filmsupply</div>

        <div className="sub-header">
          <div className="footage-tracker">Footage Tracker</div>
        </div>
      </div>

      {!req ? (
        <div className="spinner">
          <div className="loadingio-spinner-rolling-75vipoblcv5">
            <div className="ldio-949b26oganr">
              <div></div>
            </div>
          </div>
        </div>
      ) : error ? (
        <div className="all-individual-shoot">
          <div className="main-block">
            <div className="page-not-found-title">Inaccurate Data</div>
            <div className="description">
              Please navigate back to your{" "}
              <a
                className="white-link"
                href="https://www.filmsupply.com/dashboard/footage-manager"
                rel="noopener noreferrer"
              >
                Footage Manager
              </a>{" "}
              to navigate to your shoots. If this error persists, please contact{" "}
              <a className="white-link" href="mailto:content@filmsupply.com" rel="noopener noreferrer">
                content@filmsupply.com
              </a>
            </div>
          </div>
        </div>
      ) : (
        <Fragment>
          <div key={shoot.id}>
            <div key={filmmaker.fsAdminId}>
              <Link to={`/filmmakers/${filmmaker.fsAdminId * 9575}/shoots`} style={{ textDecoration: "none" }}>
                <div className="shoot-name-banner">
                  <div className="arrow-back">
                    <img src={arrowImage} alt="arrow" />
                  </div>

                  <div className="shoot-title-section">
                    <div className="shoot-title">Shoot Name</div>

                    <div className="shoot-name">{shoot.contents}</div>
                  </div>
                </div>
              </Link>
              <div
                className={
                  shoot.stage === "Completed" ||
                  shoot.stage === "Staff Pick" ||
                  shoot.stage === "Add to Collection" ||
                  (shoot.stage === "Delete Footage" && shoot.denialApproved !== true) ||
                  (shoot.stage === "Set Up" && shoot.sendtoCuration !== true) ||
                  shoot.stage === "Pre-Approval"
                    ? "blank"
                    : "shoot-name-header"
                }
              >
                <div className="shoot-title-section">
                  {shoot.denialApproved ? (
                    <div className="top-error">
                      <div className="hold-error">
                        <img src={deniedBlack} alt="denied" className="denied-circle" />
                        <div className="denied-description">This shoot was not accepted</div>
                      </div>
                      <div className="error-details">
                        {shoot.denialApproved &&
                        (shoot.denialRequestCreative !== null ||
                          shoot.denialRequestNotLicensable !== null ||
                          shoot.denialRequestFileIssue !== null ||
                          shoot.denialRequestResolution !== null ||
                          shoot.denialRequestNoise !== null ||
                          shoot.denialRequestStabilize !== null ||
                          shoot.denialRequestLens !== null ||
                          shoot.denialRequestExportError !== null ||
                          shoot.denialRequestEditing !== null ||
                          shoot.denialRequestColor !== null ||
                          shoot.denialRequestReleases !== null ||
                          shoot.denialRequestLogos !== null ||
                          shoot.denialRequestSuggestive !== null ||
                          shoot.denialRequestGore !== null ||
                          shoot.denialRequestDuplicate !== null ||
                          shoot.denialRequestOther !== null) ? (
                          <Fragment>
                            <div>
                              Filmsupply is always trying to curate the best online library of authentic and cinematic
                              footage available for licensing, and unfortunately, after careful consideration, we have
                              decided to pass on the footage for the following reasons:
                            </div>
                            <ul className="asset-reasons">
                              {shoot.denialRequestCreative && (
                                <li className="individual-reason">
                                  The footage does not fit the overall cinematic direction of the online curated
                                  library.
                                </li>
                              )}
                              {shoot.denialRequestNotLicensable && (
                                <li className="individual-reason">
                                  We feel the content of the footage is not viable for commercial licensing and lacking
                                  customer demand.
                                </li>
                              )}
                              {shoot.denialRequestFileIssue && (
                                <li className="individual-reason">
                                  Footage contains technical errors associated with the files.
                                </li>
                              )}
                              {shoot.denialRequestResolution && (
                                <li className="individual-reason">Footage is not 1080 pixels tall.</li>
                              )}
                              {shoot.denialRequestNoise && (
                                <li className="individual-reason">
                                  Footage has excessive amounts of noise and/or grain.
                                </li>
                              )}
                              {shoot.denialRequestStabilize && (
                                <li className="individual-reason">
                                  Footage needs stabilization or stabilization was applied but has technical issues.
                                </li>
                              )}
                              {shoot.denialRequestLens && (
                                <li className="individual-reason">
                                  Dirty lens with smudges on the majority of the footage.
                                </li>
                              )}
                              {shoot.denialRequestExportError && (
                                <li className="individual-reason">
                                  Footage contains export errors, such as glitches, “offline media” warning banners,
                                  letterboxing, black frames, etc.
                                </li>
                              )}
                              {shoot.denialRequestEditing && (
                                <li className="individual-reason">
                                  The footage is not trimmed to the Filmsupply guidelines and contains too many similar
                                  shots and/or shots that are too long or too short.
                                </li>
                              )}
                              {shoot.denialRequestColor && (
                                <li className="individual-reason">
                                  The footage is not colored according to the Filmsupply color guidelines.
                                </li>
                              )}
                              {shoot.denialRequestReleases && (
                                <li className="individual-reason">
                                  The footage does not have the appropriate talent or property releases making it
                                  nonviable for commercial licenses.
                                </li>
                              )}
                              {shoot.denialRequestLogos && (
                                <li className="individual-reason">
                                  The footage contains trademarks and logos that are featured too prominent and making
                                  the footage nonviable for commercial licensing.
                                </li>
                              )}
                              {shoot.denialRequestSuggestive && (
                                <li className="individual-reason">
                                  The footage contains nudity or is sexually suggestive and nonviable for commercial
                                  licensing.
                                </li>
                              )}
                              {shoot.denialRequestGore && (
                                <li className="individual-reason">
                                  The footage contains too much gore/violence and is nonviable for commercial licensing.
                                </li>
                              )}
                              {shoot.denialRequestDuplicate && (
                                <li className="individual-reason">
                                  The footage is already in a shoot associated with your profile.
                                </li>
                              )}
                              {shoot.denialRequestOther && (
                                <li className="individual-reason">{shoot.denialRequestOther}</li>
                              )}
                            </ul>
                          </Fragment>
                        ) : (
                          <div className="backlog-deny">
                            Filmsupply is always trying to curate the best online library of authentic and cinematic
                            footage available for licensing, and unfortunately, after careful consideration, we have
                            decided to pass on the footage.
                          </div>
                        )}
                        <div>
                          We try to maintain a high quality standard and always showcase the best of your work. If you
                          feel this footage reflects your best work and you would like us to reconsider, please contact{" "}
                          <a
                            href="mailto:content@filmsupply.com"
                            className="individual-link"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            content@filmsupply.com
                          </a>
                        </div>
                      </div>
                    </div>
                  ) : shoot.assetsRequested ? (
                    <div>
                      <div className="top-error">
                        <div className="hold-error">
                          <img src={holdImage} alt="on-hold" />
                          <div className="hold-error-message">On Hold</div>
                        </div>
                        <div className="error-details">
                          <div>There are issues that need to be resolved before we can proceed:</div>
                          <ul className="asset-reasons">
                            {shoot.assetsNeedReleases && (
                              <li className="individual-reason">
                                Do you have all releases for all the talent in this shoot?
                                <span className="regular-text">
                                  {" "}
                                  We don't currently have all releases for all people that appear in the footage. We
                                  require all releases for all talent releases before we start curation on this footage.
                                  If you need help obtaining talent releases, visit our{" "}
                                  <a
                                    href="https://filmmakers.filmsupply.com/releases"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    releases page
                                  </a>
                                  .
                                </span>
                              </li>
                            )}
                            {shoot.assetsNeedTrimming && (
                              <li className="individual-reason">
                                Would you be able to send over selects/project files for this shoot?
                                <span className="regular-text">
                                  {" "}
                                  The footage is not trimmed to{" "}
                                  <a
                                    href="https://filmmakers.filmsupply.com/send-footage"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    the Filmsupply guidelines
                                  </a>{" "}
                                  and contains too many similar shots and/or too many shots over 30 seconds. We require
                                  filmmakers to send in their footage cut down to the best moments - and having selects
                                  will help speed up the turnaround time to get your footage up on the site! Since we
                                  have your footage, sending a project file might be the best option.
                                </span>
                              </li>
                            )}
                            {shoot.assetsTechnicalProblem && (
                              <li className="individual-reason">
                                There were technical issues with the footage that will require you to re-encode and
                                re-upload the footage.
                              </li>
                            )}
                            {shoot.assetsOtherReason && (
                              <li className="individual-reason">{shoot.assetsOtherReason}</li>
                            )}
                          </ul>
                          <div>
                            Until those assets are received, this shoot will remain “On Hold”. To resolve these issues,
                            please contact{" "}
                            <a
                              href="mailto:content@filmsupply.com"
                              className="individual-link"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              content@filmsupply.com
                            </a>
                            .
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="shoot-title">Available in Footage Manager</div>
                      <div className="shoot-name">
                        <Moment format="MMMM D">{shoot.completionDate1}</Moment>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className={shoot.denialApproved ? "blank" : "shoot-name-header"}>
                <div className="shoot-title-section">
                  <div className="status">
                    <div>
                      {shoot.assetsRequested ? (
                        <div></div>
                      ) : shoot.stage === "Completed" ||
                        shoot.stage === "Staff Pick" ||
                        shoot.stage === "Add to Collection" ||
                        shoot.stage === "Delete Footage" ? (
                        <div className="status-section">
                          <div className="status-title-text">Status:</div>
                          <div className="status-text-top">Completed</div>
                        </div>
                      ) : shoot.stage === "Set Up" &&
                        shoot.sendtoCuration !== true &&
                        shoot.reviewStatus !== "Approved" ? (
                        <div className="status-section">
                          <div className="status-title-text">Status:</div>
                          <div className="status-text-top">Waiting On Upload</div>
                        </div>
                      ) : shoot.stage === "Pre-Approval" ? (
                        <div className="status-section">
                          <div className="status-title-text">Status:</div>
                          <div className="status-text-top">Pre-Approval Review</div>
                        </div>
                      ) : shoot.stage === "Set Up" && shoot.reviewStatus === "Approved" && !shoot.sendtoCuration ? (
                        <div className="status-section">
                          <div className="status-title-text">Status:</div>
                          <div className="status-text-top">Ready For Upload</div>
                        </div>
                      ) : (
                        <div className="status-section">
                          <div className="status-title-text">Status:</div>
                          <div className="status-text-top">On Schedule</div>
                        </div>
                      )}{" "}
                    </div>
                  </div>

                  <div className="progress-section">
                    {shoot.stage === "Pre-Approval" ||
                    (shoot.stage === "Set Up" && shoot.reviewStatus === "Approved" && !shoot.sendtoCuration) ? (
                      <Fragment>
                        <div className="progress-line-pre-approval"></div>
                        <div>
                          <div className="status-step">
                            <div className="status-circle-done"></div>
                            <div className="status-text">Review Link Received</div>
                          </div>

                          <div className="status-step">
                            <div
                              className={
                                shoot.stage === "Pre-Approval" &&
                                (shoot.reviewStatus === "Needs Review" || shoot.reviewStatus === "Set Up")
                                  ? "status-circle-current"
                                  : "status-circle-done"
                              }
                            ></div>
                            <div
                              className={
                                shoot.stage === "Pre-Approval" &&
                                (shoot.reviewStatus === "Needs Review" || shoot.reviewStatus === "Set Up")
                                  ? "status-text-selected"
                                  : "status-text"
                              }
                            >
                              Footage Under Review
                            </div>
                          </div>

                          <div className="status-step">
                            <div
                              className={
                                shoot.stage === "Pre-Approval" && shoot.reviewStatus === "Feedback Provided"
                                  ? "status-circle-current"
                                  : shoot.reviewStatus === "Approved"
                                  ? "status-circle-done"
                                  : "status-circle"
                              }
                            ></div>
                            <div
                              className={
                                shoot.stage === "Pre-Approval" && shoot.reviewStatus === "Feedback Provided"
                                  ? "status-text-selected"
                                  : "status-text"
                              }
                            >
                              Feedback Provided
                            </div>
                          </div>

                          <div className="status-step-bottom">
                            <div
                              className={shoot.reviewStatus === "Approved" ? "status-circle-current" : "status-circle"}
                            ></div>
                            <div className={shoot.reviewStatus === "Approved" ? "status-text-selected" : "status-text"}>
                              Footage Approved
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className="progress-line"></div>
                        <div>
                          <div className="status-step">
                            <div
                              className={
                                shoot.stage === "Set Up" && shoot.sendtoCuration === null
                                  ? "status-circle-current"
                                  : "status-circle-done"
                              }
                            ></div>
                            <div
                              className={
                                shoot.stage === "Set Up" && shoot.sendtoCuration === null
                                  ? "status-text-selected"
                                  : "status-text"
                              }
                            >
                              Footage Uploading
                            </div>
                          </div>

                          <div className="status-step">
                            <div
                              className={
                                shoot.stage === "Set Up" && shoot.sendtoCuration === null
                                  ? "status-circle"
                                  : shoot.stage === "Transfer Footage" ||
                                    (shoot.stage === "Set Up" && shoot.sendtoCuration !== null)
                                  ? "status-circle-current"
                                  : "status-circle-done"
                              }
                            ></div>
                            <div
                              className={
                                shoot.stage === "Transfer Footage" ||
                                (shoot.stage === "Set Up" && shoot.sendtoCuration !== null)
                                  ? "status-text-selected"
                                  : "status-text"
                              }
                            >
                              Footage Received
                            </div>
                          </div>

                          <div className="status-step">
                            <div
                              className={
                                shoot.stage === "Set Up" && shoot.sendtoCuration === null
                                  ? "status-circle"
                                  : shoot.stage === "Transfer Footage" ||
                                    (shoot.stage === "Set Up" && shoot.sendtoCuration !== null)
                                  ? "status-circle"
                                  : shoot.stage === "Curate" || shoot.stage === "Curation Review"
                                  ? "status-circle-current"
                                  : "status-circle-done"
                              }
                            ></div>
                            <div
                              className={
                                shoot.stage === "Curate" || shoot.stage === "Curation Review"
                                  ? "status-text-selected"
                                  : "status-text"
                              }
                            >
                              Curation
                            </div>
                          </div>

                          <div className="status-step">
                            <div
                              className={
                                shoot.stage === "Transfer Footage" ||
                                shoot.stage === "Set Up" ||
                                shoot.stage === "Curate" ||
                                shoot.stage === "Curation Review"
                                  ? "status-circle"
                                  : shoot.stage === "Color" || shoot.stage === "Color Review"
                                  ? "status-circle-current"
                                  : "status-circle-done"
                              }
                            ></div>
                            <div
                              className={
                                shoot.stage === "Color" || shoot.stage === "Color Review"
                                  ? "status-text-selected"
                                  : "status-text"
                              }
                            >
                              Color
                            </div>
                          </div>

                          <div className="status-step">
                            <div
                              className={
                                shoot.stage === "Transfer Footage" ||
                                shoot.stage === "Set Up" ||
                                shoot.stage === "Curate" ||
                                shoot.stage === "Curation Review" ||
                                shoot.stage === "Color" ||
                                shoot.stage === "Color Review"
                                  ? "status-circle"
                                  : shoot.stage === "Export" ||
                                    shoot.stage === "Organize" ||
                                    shoot.stage === "Sync" ||
                                    shoot.stage === "Import"
                                  ? "status-circle-current"
                                  : "status-circle-done"
                              }
                            ></div>
                            <div
                              className={
                                shoot.stage === "Export" ||
                                shoot.stage === "Organize" ||
                                shoot.stage === "Sync" ||
                                shoot.stage === "Import"
                                  ? "status-text-selected"
                                  : "status-text"
                              }
                            >
                              Importing to Site
                            </div>
                          </div>

                          <div className="status-step">
                            <div
                              className={
                                shoot.stage === "Transfer Footage" ||
                                shoot.stage === "Set Up" ||
                                shoot.stage === "Curate" ||
                                shoot.stage === "Curation Review" ||
                                shoot.stage === "Color" ||
                                shoot.stage === "Color Review" ||
                                shoot.stage === "Export" ||
                                shoot.stage === "Organize" ||
                                shoot.stage === "Sync" ||
                                shoot.stage === "Import"
                                  ? "status-circle"
                                  : shoot.stage === "Import Review" || shoot.stage === "Final Review"
                                  ? "status-circle-current"
                                  : "status-circle-done"
                              }
                            ></div>
                            <div
                              className={
                                shoot.stage === "Import Review" || shoot.stage === "Final Review"
                                  ? "status-text-selected"
                                  : "status-text"
                              }
                            >
                              Adding Metadata
                            </div>
                          </div>

                          <div className="status-step-bottom">
                            {shoot.stage === "Transfer Footage" ||
                            shoot.stage === "Set Up" ||
                            shoot.stage === "Curate" ||
                            shoot.stage === "Curation Review" ||
                            shoot.stage === "Color" ||
                            shoot.stage === "Color Review" ||
                            shoot.stage === "Export" ||
                            shoot.stage === "Organize" ||
                            shoot.stage === "Sync" ||
                            shoot.stage === "Import" ||
                            shoot.stage === "Import Review" ||
                            shoot.stage === "Final Review" ? (
                              <div className="status-circle"></div>
                            ) : (
                              <div className="status-completed">
                                <img src={completedImage} alt="completed" />
                              </div>
                            )}
                            <div
                              className={
                                shoot.stage === "Completed" ||
                                shoot.stage === "Staff Pick" ||
                                shoot.stage === "Add to Collection" ||
                                shoot.stage === "Delete Footage"
                                  ? "status-text-selected"
                                  : "status-text"
                              }
                            >
                              Available in Footage Manager
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                  {shoot.stage === "Completed" ||
                  shoot.stage === "Staff Pick" ||
                  shoot.stage === "Add to Collection" ||
                  shoot.stage === "Delete Footage" ? (
                    <a
                      href={`https://www.filmsupply.com/dashboard/footage-manager/shoots/${shoot.fsAdminShootId}/clips`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="footage-manager-button"
                    >
                      <div>
                        <b>Go to Footage Manager</b>
                      </div>
                    </a>
                  ) : shoot.stage === "Pre-Approval" &&
                    (shoot.reviewStatus === "Needs Review" || shoot.reviewStatus === "Feedback Provided") ? (
                    <Fragment>
                      <div className="review-buttons">
                        <div className="review-button" onClick={() => window.open(shoot.reviewLink, "_blank")}>
                          {shoot.stage === "Pre-Approval" && shoot.reviewStatus === "Needs Review"
                            ? "See Review Link"
                            : "See Feedback"}
                        </div>
                        {shoot.stage === "Pre-Approval" && shoot.reviewStatus === "Feedback Provided" && (
                          <div
                            className="review-button space-right"
                            onClick={() =>
                              window.open(
                                `https://upload.filmsupply.com?username=${filmmaker.fsAdminId}&shoot_id=${shoot.id}`,
                                "_blank"
                              )
                            }
                          >
                            Upload New Stringout
                          </div>
                        )}
                      </div>

                      {shoot.stage === "Pre-Approval" && shoot.reviewStatus === "Feedback Provided" && (
                        <div className="review-link-details">
                          <span className="feedback-date">
                            Feedback last provided:{" "}
                            <b>
                              <Moment format="MMMM D, YYYY">{shoot.reviewLastFeedback}</Moment>
                            </b>
                          </span>
                        </div>
                      )}
                    </Fragment>
                  ) : (
                    shoot.stage === "Set Up" &&
                    shoot.reviewStatus === "Approved" &&
                    !shoot.sendtoCuration && (
                      <Fragment>
                        <div
                          className="review-button"
                          onClick={() =>
                            window.open(
                              `https://upload.filmsupply.com?username=${filmmaker.fsAdminId}&shoot_id=${shoot.id}`,
                              "_blank"
                            )
                          }
                        >
                          Upload Footage
                        </div>
                        <div
                          className="upload-text"
                          onClick={() => window.open("https://filmmakers.filmsupply.com/send-footage/", "_blank")}
                        >
                          Click here for tips and tutorials for exporting individual clips
                        </div>
                      </Fragment>
                    )
                  )}
                </div>
              </div>

              <div className="shoot-details-header">
                <div className="shoot-title-section">
                  <div className="shoot-details-title">
                    <b>Shoot Details</b>
                  </div>

                  <div className="grid-container">
                    <div className="grid-title">
                      <b>Shoot ID</b>
                    </div>
                    <div className="grid-detail">
                      <div>{shoot.id}</div>
                    </div>
                  </div>

                  <div className="grid-container">
                    <div className="grid-title">
                      <b>Date Received</b>
                    </div>
                    <div className="grid-detail">
                      <div>
                        <Moment format="MMMM D, YYYY">{shoot.createdAt}</Moment>
                      </div>
                    </div>
                  </div>

                  <div className="grid-container">
                    <div className="grid-title">
                      <b>Original Name</b>
                    </div>
                    <div className="grid-detail">
                      <div>{shoot.originalShootName}</div>
                    </div>
                  </div>

                  <div className="grid-container">
                    <div className="grid-title">
                      <b>Releases Received</b>
                    </div>
                    <div className="grid-detail">
                      <div>
                        {shoot.releasesCount === null ? "0 Releases" : <div>{shoot.releasesCount} Releases</div>}
                      </div>
                    </div>
                  </div>

                  <div className="grid-container">
                    <div className="grid-title">
                      <b>Footage Received</b>
                    </div>
                    <div className="grid-detail">
                      {shoot.stage === "Transfer Footage" ||
                      shoot.stage === "Set Up" ||
                      (shoot.ingestMethod === "Manual Footage" &&
                        (shoot.totalClips === null || shoot.duration === null)) ? (
                        "-"
                      ) : (
                        <div>
                          {shoot.totalClips} Clips, {shoot.duration} Hours
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="grid-container">
                    <div className="grid-title">
                      <b>Footage Accepted</b>
                    </div>
                    <div className="grid-detail">
                      {shoot.stage === "Completed" ||
                      shoot.stage === "Staff Pick" ||
                      shoot.stage === "Add to Collection" ||
                      shoot.stage === "Delete Footage" ? (
                        <div>
                          {shoot.exportReviewClipCount} Clips, {shoot.curationReviewDuration} Hours
                        </div>
                      ) : shoot.stage === "Denied" ? (
                        <div>0 Clips, 0 hours</div>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>

                  <div className="grid-container-bottom">
                    <div className="grid-title">
                      <b>From a Film</b>
                    </div>
                    <div className="grid-detail">{shoot.filmId !== null ? "Yes" : "No"}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}
