import React from "react";
import Button from "components/Button";
import styles from "./timeout.module.scss";

interface Props {
  onDismiss: Function,
  onConfirm: Function,
}

export default function Timeout(props : Props) {
  return (
    <div>
        <div className={styles.overlay}></div>
        <div className={styles.timeoutContainer}>
            <h2>Is this shoot ready to be sent to the curation team?*</h2>
            <div className={styles.buttons}>
                <Button variant="gray" onClick={() => props.onDismiss()}>
                  <span style={{ textTransform: 'uppercase' }}>Not yet, still uploading</span>
                </Button>
                &nbsp;
                <Button onClick={() => props.onConfirm()}>
                  <span style={{ textTransform: 'uppercase' }}>Yes, send to curation</span>
                </Button>
            </div>
            <div className={styles.details}>* To maximize efficiency, please upload the entirety of the footage for this
                shoot before sending to curation. Please avoid sending footage from the same shoot across multiple
                sessions, if possible.</div>
        </div>
    </div>
  );
}