import type React from 'react';
import { type ReactNode, createRef, useEffect, useMemo } from 'react';
import Dropzone, { type DropzoneRef, type FileRejection, type FileWithPath } from 'react-dropzone';
import { UploadStatus, type RejectedFile, type UploaderFile } from "../types";
import { type Progress, UploadFile } from "./UploadFile";
import { humanFileSize, humanTime } from "../utils";
import { useFiles } from "../hooks/useFiles";
import upload from "images/upload.svg";
import styles from './generic-uploader.module.scss';

type UploadId = string;

type OnRemove = () => void;

interface Props {
  accept: Record<string, Array<string>>;
  prefix?: string;
  shouldUseMultipart: (file: File) => boolean;
  retries?: number;
  getChunkSize?: (file: File) => number;
  signUpload?: (file: File) => Promise<string>;
  createMultipartUpload?: (file: File) => Promise<UploadId>;
  signPart?: (file: File, chunk: number) => Promise<string>;
  onUploadCompleted?: (file: File) => void;
  onIsProcessingChange?: (isProcessing: boolean) => void;
}

const isAcceptedFile = (file: RejectedFile|UploaderFile): file is UploaderFile => Object.hasOwn(file, "status");

const isRejectedFile = (file: RejectedFile|UploaderFile): file is RejectedFile => ! isAcceptedFile(file);

const borderColor = (focused: boolean, accepted: boolean, rejected: boolean): string => {
  if (focused) return '#2196f3';
  
  if (accepted) return '#00e676';
  
  if (rejected) return '#ff1744';
}

export default function SignedUrlAwsUploader (props: Props) {
  const { onIsProcessingChange } = props;
  const dropzoneRef: React.RefObject<DropzoneRef> = createRef();

  const { files, isProcessing, handleDrop, processNext, removeFile, markUploaded, markAborted, markFailed } = useFiles();

  const uploadedCount = useMemo(() => {
    return files.filter(file => file.status === UploadStatus.Success).length
  }, [files]);

  const queuedCount = useMemo(() => {
    return files.filter(file => file.status === UploadStatus.Queued).length
  }, [files]);

  useEffect(() => {
    if (queuedCount > 0) {
      processNext();
    }
  }, [processNext, queuedCount]);

  useEffect(() => {
    onIsProcessingChange?.(isProcessing);
  }, [isProcessing, onIsProcessingChange]);

  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open()
    }
  };

  const usesMultipartUpload = (file: FileWithPath) => {
    return props.shouldUseMultipart(file);
  }

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (queuedCount) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [queuedCount]);

  const handleSuccess = (id: string, file: File) => {
    markUploaded(id);

    props.onUploadCompleted(file);

    processNext();
  }

  const handleError = (id: string, error: Error) => {
    markFailed(id);

    processNext();
  }

  const handleAborted = (id: string) => {
    markAborted(id);

    processNext();
  }

  return (
    <div>
      {uploadedCount > 0 && (
        <div className={styles.statusBar}>{uploadedCount} of {files.length} files successfully uploaded</div>
      )}
      <div className={styles.uploadContainer}>
        <Dropzone ref={dropzoneRef} onDrop={handleDrop} accept={props.accept} noClick noKeyboard>
          {({getRootProps, getInputProps, isFocused, isDragAccept, isDragReject}) => (
            <section>
              <div {...getRootProps({className: styles.dropzone})} style={{ borderColor: borderColor(isFocused, isDragAccept, isDragReject)}}>
                <input {...getInputProps()} />
                <p>Drag and drop files or folders or <button className={styles.linkButton} type="button" onClick={openDialog}>click</button> to select files to start uploading.</p>
                <img src={upload} alt="upload icon" height={40} draggable="false" />
              </div>
            </section>
          )}
        </Dropzone>
        <div>
        {files.map((entry: RejectedFile|UploaderFile, _index: number) => (
          <div key={entry.id} className={styles.uploadRow}>
            {isRejectedFile(entry) && (
              <RejectedFileRow file={entry.file} error={entry.error} onRemove={() => removeFile(entry.id)} />
            )}

            {isAcceptedFile(entry) && entry.status === UploadStatus.Error && (
              <ErroredFileRow file={(entry.file as FileWithPath)} />
            )}

            {isAcceptedFile(entry) && entry.status === UploadStatus.Aborted && (
              <AbortedFileRow file={(entry.file as FileWithPath)} />
            )}

            {isAcceptedFile(entry) && entry.status === UploadStatus.Queued && (
              <QueuedFileRow file={(entry.file as FileWithPath)} onRemove={() => removeFile(entry.id)} />
            )}

            {isAcceptedFile(entry) && entry.status === UploadStatus.Processing && (
              <UploadFile
                prefix={props.prefix}
                file={(entry.file as FileWithPath)}
                retries={props.retries}
                chunkSize={usesMultipartUpload(entry.file as FileWithPath) ? props.getChunkSize(entry.file as FileWithPath) : null}
                shouldUseMultipart={usesMultipartUpload(entry.file as FileWithPath)}
                beforeUpload={usesMultipartUpload(entry.file as FileWithPath) ? props.createMultipartUpload : null}
                beforeChunkUpload={usesMultipartUpload(entry.file as FileWithPath) ? props.signPart : props.signUpload}
                onSuccess={() => handleSuccess(entry.id, entry.file as FileWithPath)}
                onError={(error: Error) => handleError(entry.id, error)}
              >
                {(progress: Progress) => (
                  <>
                    <div className={styles.uploadProgress} style={{ backgroundColor: '#119a91', width: `${progress.percentage}%` }}>
                      <span className={styles.uploadProgressStripes} />
                    </div>
                    <div className={styles.filename}>{(entry.file as FileWithPath).path}</div>
                    <div className={styles.size}>{humanFileSize(progress.sent, 2)} / {humanFileSize((entry.file as FileWithPath).size, 2)}</div>
                    <div className={styles.elapsed}>{humanTime(progress.pendingTime)} - {progress.percentage}%</div>
                  </>
                )}
              </UploadFile>
            )}

            {isAcceptedFile(entry) && entry.status === UploadStatus.Success && (
              <UploadedFileRow file={(entry.file as FileWithPath)} />
            )}
          </div>
        ))}
        </div>
      </div>          
    </div>
  );
}

const UploadedFileRow = (props: { file: FileWithPath }) => {
  const { file } = props;

  return (
    <>
      <div className={styles.uploadProgress} style={{ backgroundColor: '#119a91', width: '100%' }} />
      <div className={styles.filename}>{file.path} - <b>Uploaded!</b></div>
      <div className={styles.size}>{humanFileSize(file.size, 2)} / {humanFileSize(file.size, 2)}</div>
      <div className={styles.elapsed}>100%</div>
    </>
  );
}

const QueuedFileRow = (props: { file: FileWithPath, onRemove: OnRemove }) => {
  const { file, onRemove } = props;

  return (
    <>
      <div className={styles.uploadProgress} style={{ backgroundColor: '#c7c7c7', width: '100%' }}>
        <span className={styles.uploadProgressStripes} />
      </div>                    
      <div className={styles.filename}>{file.path} - <b>Queued</b></div>
      <button type="button" onClick={() => onRemove()} className={styles.cancelButton}>&times;</button>
    </>
  );
}

const ErroredFileRow = (props: { file: FileWithPath }) => {
  const { file } = props;

  return (
    <>
      <div className={styles.uploadProgress} style={{ backgroundColor: '#fae3e1', width: '100%' }} />
      <div className={styles.filename}>{file.path} - <b>Error</b></div>
    </>
  )
}

const AbortedFileRow = (props: { file: FileWithPath }) => {
  const { file } = props;

  return (
    <>
      <div className={styles.uploadProgress} style={{ backgroundColor: '#fae3e1', width: '100%' }} />
      <div className={styles.filename}>{file.path} - <b>Aborted by the user!</b></div>
    </>
  )
}

const RejectedFileRow = (props: { file: File, error: string, onRemove: OnRemove}) => {
  const { file, error, onRemove } = props;

  return (
    <>
      <div className={styles.uploadProgress} style={{ backgroundColor: '#fae3e1', width: '100%' }} />
      <div className={styles.filename}>{file.name} - {error}</div>
      <DeleteButton onClick={onRemove}>&times;</DeleteButton>
    </>
  );
}

const DeleteButton = (props: { children: ReactNode, title?: string, onClick: () => void}) => (
  <button type="button" title={props.title} className={styles.deleteButton} onClick={props.onClick}>
    {props.children}
  </button>
);