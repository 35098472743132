import React from "react";
import PropTypes from "prop-types";
import { useFieldArray, useFormContext } from "react-hook-form";
import Talent from "./Talent";
import Property from "./Property";
import { Talent as TalentType, Property as PropertyType, Recipients, ReleaseType } from "../types";

interface IProps {
  release: ReleaseType
}

const MAX_RECIPIENTS = 100;

export default function RecipientsArray({ release }: IProps) {
  const { register, getValues, setValue, trigger, watch, formState: { errors } } = useFormContext(); 

  const { fields, append, remove } = useFieldArray<Recipients, "recipients", "id">({
    name: "recipients"
  });

  const addNew = () => {
    if (release === "talent") {
      const talent: TalentType = {
        email: "",
        compensation: null,
        royaltyPercentage: null,
        royaltyCap: null,
        royaltyPercentageAfterCap: null,
        royaltyCheckmark: false
      };
  
      append(talent);
    } else if (release === "property") {
      const property: PropertyType = {
        email: "",
        description: "",
        compensation: null,
        royaltyPercentage: null,
        royaltyCap: null,
        royaltyPercentageAfterCap: null,
        royaltyCheckmark: false        
      };
  
      append(property);
    }
  }

  return (
    <>
      {fields.map((t, index) => {
        if (release === "talent") {
          return (
            <Talent
              key={t.id}
              index={index}
              onDelete={(i) => remove(i)}
              errors={errors.recipients?.[index]}
              {...{ register, trigger, getValues, setValue, watch }}
            />
          )
        } else if (release === "property") {
          return (
            <Property
              key={t.id}
              index={index}
              onDelete={(i) => remove(i)}
              errors={errors.recipients?.[index]}
              {...{ register, trigger, getValues, setValue, watch }}
            />
          )
        }
      })}
      <hr />
      <button
        type="button"
        className="add-talent"
        onClick={addNew}
        disabled={fields.length === MAX_RECIPIENTS}
      >
        + Add {release === "talent" ? "Talent" : "Property"}
      </button>
    </>
  );
}

RecipientsArray.propTypes = {
  release: PropTypes.oneOf(["talent", "property"]).isRequired
}