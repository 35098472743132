import axios from "axios";
import React, { useState, useEffect, createRef, Fragment } from "react";
// import SignatureCanvas from "react-signature-canvas";
import "./revenue-agreement.scss";
import Moment from "react-moment";
import check from "./images/check.svg";
import downArrow from "./images/down-arrow.svg";
var validator = require("email-validator");
import { countries } from "./constants";
export default function RoyaltySplit(props: any) {
  const [firstName, setFirstName] = useState<any>("");
  const [lastName, setLastName] = useState<any>("");
  const [id, setId] = useState<any>(null);
  const [checkbox, setCheckbox] = useState<any>(false);
  const [country, setCountry] = useState<any>(false);
  const [countryName, setCountryName] = useState<any>("Country");
  const [entity, setEntity] = useState<any>(false);
  const [entityName, setEntityName] = useState<any>("Legal Entity Type");
  const [formData, setFormData] = useState<any>("");
  const [loadSuccess, setLoadSuccess] = useState<any>(null);
  const [loadError, setLoadError] = useState<any>(null);
  const [agreement, setAgreement] = useState<any>(null);
  const [submission, setSubmission] = useState<boolean | null>(false);
  const [formSuccess, setFormSuccess] = useState<boolean | null>(false);

  const scroll: any = createRef();

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const idValue: any = params.get("id");

    document.title = "Revenue Agreement";

    const maskedId = idValue / 1067685;

    axios({
      method: "POST",
      data: {
        payee_id: maskedId
      },
      url: `${process.env.REACT_APP_API_URL}/payee-info`,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY
      }
    }).then(function(res) {
      const payee = res.data.payee;
      if (payee) {
        setFirstName(payee.firstName);
        setLastName(payee.lastName);
        setId(idValue);
        setFormData({
          id: idValue,
          first_name: payee.firstName,
          last_name: payee.lastName,
          street_address: "",
          city: "",
          state_province: "",
          postal_zip: "",
          country: "",
          phone_number: "",
          email: "",
          type: "",
          company_name: "",
          title: ""
        });
      }

      if (!payee) {
        setLoadError(true);
      }

      if (res.data.agreement) {
        setLoadError(true);
        setAgreement(true);
      }
      setLoadSuccess(true);
    });
  }, [props.location.search]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  function submitAgreement(e: any) {
    setSubmission(true);
    e.preventDefault();

    axios({
      method: "POST",
      data: {
        form: { formData }
      },
      url: `${process.env.REACT_APP_API_URL}/revenue-agreement`,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY
      }
    }).then(function(res) {
      setFormSuccess(true);
    });
  }

  function toggleCheckbox() {
    setCheckbox(!checkbox);
  }

  function scrollToText() {
    scroll.current.scrollIntoView();
  }

  const validation =
    formData.first_name === "" ||
    formData.last_name === "" ||
    formData.street_address === "" ||
    formData.city === "" ||
    formData.state_province === "" ||
    formData.postal_zip === "" ||
    formData.country === "" ||
    formData.phone_number === "" ||
    !validator.validate(formData.email) ||
    formData.type === "" ||
    (formData.type === "Company" && (formData.company_name === "" || formData.title === "")) ||
    checkbox === null ||
    !checkbox;

  return (
    <Fragment>
      {!loadSuccess ? (
        <div className="spinner">
          <div className="loadingio-spinner-rolling-75vipoblcv5">
            <div className="ldio-949b26oganr">
              <div></div>
            </div>
          </div>
        </div>
      ) : id === null || agreement ? (
        <div className="all">
          <div className="main-block">
            <div className="page-not-found-title">
              {!loadError
                ? "Missing Data"
                : loadError && !agreement
                ? "Inaccurate Data"
                : loadError && agreement && "Agreement Signed"}
            </div>
            <div className="description">
              {agreement
                ? "Our records show you have already signed a royalty agreement. If you feel this is a mistake or have further questions, please"
                : "There is necessary information missing from this form. Please try the link in your email again. If this error persists, please"}{" "}
              contact{" "}
              <a className="white-link" href="mailto:content@filmsupply.com" rel="noopener noreferrer">
                content@filmsupply.com
              </a>
            </div>
          </div>
        </div>
      ) : !formSuccess ? (
        <div className="revenue-agreement-all">
          <div className="address-section">
            <div className="logo">
              <div>F</div>
            </div>
            <div className="address">
              <div style={{ fontWeight: 600 }}>FILMSUPPLY</div>
              <div>9555 Harmon Rd. Forth Worth, TX 76177</div>
              <div>1-800-805-6802</div>
            </div>
          </div>
          <div className="collaborator">Collaborator Information</div>

          <form>
            <div className="inputs">
              <input
                onChange={handleChange}
                placeholder="First Name"
                className={formData.first_name === "" || formData.first_name === null ? "input error" : "input correct"}
                name="first_name"
                defaultValue={firstName}
              />

              <input
                onChange={handleChange}
                placeholder="Last Name"
                name="last_name"
                className={formData.last_name === "" || formData.last_name === null ? "input error" : "input correct"}
                defaultValue={lastName}
              />

              <input
                onChange={handleChange}
                placeholder="Street Address"
                className={formData.street_address === "" ? "input error" : "input correct"}
                name="street_address"
              />

              <input
                onChange={handleChange}
                className={formData.city === "" ? "input error" : "input correct"}
                placeholder="City"
                name="city"
              />
              <div className="two-items">
                <input
                  onChange={handleChange}
                  placeholder="State/Province"
                  name="state_province"
                  className={formData.state_province === "" ? "side-input state error" : "side-input state correct"}
                />

                <input
                  onChange={handleChange}
                  placeholder="Postal/Zip"
                  name="postal_zip"
                  className={formData.postal_zip === "" ? "side-input error" : "side-input correct"}
                />
              </div>

              <div
                className={
                  formData.country === "" ? "input stale custom-select error" : "input stale custom-select correct"
                }
                onClick={() => setCountry(!country)}
              >
                <div style={countryName !== "Country" ? { color: "#000000" } : { color: "#757575" }}>{countryName}</div>
                <div>
                  <img className={!country ? "down-arrow" : "up-arrow"} src={downArrow} alt="down-arrow" />
                </div>
              </div>
              {country && (
                <div className="all-selects">
                  {countries.map(country => (
                    <div
                      className="individual-select"
                      key={country}
                      onClick={() => {
                        setFormData({ ...formData, country: country });
                        setCountryName(country);
                        setCountry(false);
                      }}
                    >
                      {country}
                    </div>
                  ))}
                  <div
                    onClick={() => {
                      setFormData({ ...formData, country: "Zimbabwe" });
                      setCountryName("Zimbabwe");
                      setCountry(false);
                    }}
                    className="last-select"
                  >
                    Zimbabwe
                  </div>
                </div>
              )}

              <input
                type="tel"
                onChange={handleChange}
                placeholder="Phone Number"
                name="phone_number"
                className={formData.phone_number === "" ? "input error" : "input correct"}
              />

              <input
                onChange={handleChange}
                placeholder="* Filmsupply User Email"
                name="email"
                className={!validator.validate(formData.email) ? "input error" : "input correct"}
              />

              <div className="email-text">
                * Payouts will be sent through your Filmsupply account. If you have a Filmsupply account, please type
                the corresponding email above. If you do not have a Filmsupply account, we will create one with the
                email you type above and then send your login details when we're done.
              </div>

              <div
                className={
                  formData.type === "" ? "input stale custom-select error" : "input stale custom-select correct"
                }
                onClick={() => setEntity(!entity)}
              >
                <div style={entityName !== "Legal Entity Type" ? { color: "#000000" } : { color: "#757575" }}>
                  {entityName}
                </div>
                <div>
                  <img className={!entity ? "down-arrow" : "up-arrow"} src={downArrow} alt="down-arrow" />
                </div>
              </div>
              {entity && (
                <div className="all-selects" style={{ height: "101px" }}>
                  <div
                    className="individual-select"
                    onClick={() => {
                      setFormData({
                        ...formData,
                        type: "Individual",
                        company_name: "",
                        title: ""
                      });
                      setEntityName("Individual");
                      setEntity(false);
                    }}
                  >
                    Individual
                  </div>

                  <div
                    onClick={() => {
                      setFormData({ ...formData, type: "Company" });
                      setEntityName("Company");
                      setEntity(false);
                    }}
                    className="last-select"
                  >
                    Company
                  </div>
                </div>
              )}
              {formData.type === "Company" && (
                <Fragment>
                  <input
                    onChange={handleChange}
                    placeholder="Company Name"
                    name="company_name"
                    className={formData.company_name === "" ? "input error" : "input correct"}
                  />
                  <input
                    onChange={handleChange}
                    className={formData.title === "" ? "input error" : "input correct"}
                    placeholder="Title"
                    name="title"
                  />
                </Fragment>
              )}

              <div ref={scroll} />
              <div className="agreement-title" id="test">
                Royalty Share Terms and Conditions
              </div>
              <div className="agreement-text">
                {`You have been identified by one or more content licensors (each, a “Licensor”) as a financial
                participant relative to one or more works (each, a “Licensed Work”) licensed to Filmsupply, LLC
                (“Company”) by Licensor pursuant to one or more content representation agreements (each, a
                “Representation Agreement”). Solely as an accommodation to Licensor, Company will re-direct to you
                payment of certain amounts (your “Royalties”) that are otherwise payable to Licensor pursuant to the
                Representation Agreement. In all respects, your Royalties will be calculated, adjusted, pro-rated,
                reduced (but not escalated), credited, and paid on the same basis and in the same manner as Licensor’s
                payments pursuant to the Representation Agreement at the time such payments are made. All payments will
                be made through Company’s then-currently available payment methods. If payment is made through
                electronic methods or wire transfer, you will be solely responsible for any bank fees or other charges
                related to electronic payments and funds transfers. You are solely responsible for ensuring the accuracy
                of information related to payments, and Company will have no liability whatsoever for any erroneous or
                unintended payments made based on the inaccuracy of payment information provided by you. For clarity,
                Company will have no liability to any person or entity, including you, by reason of any erroneous
                payment Company may make or any failure to comply with this authorization in any respect.
                Notwithstanding any incidental benefit conferred on you by way of Licensor’s direction, nothing herein
                or in the Representation Agreement will make you a third-party beneficiary of or a party to the
                Representation Agreement, nor will you have any right to enforce the Representation Agreement or access
                Company’s books or records. You agree that Company may cease re-directing Royalties to you at any time
                for any reason, and that this acknowledgement waives any right of action against Company for all
                purposes in connection with the Licensed Works. Company will not make payment of any Royalties until
                such time as you have submitted an IRS form W9 (or other applicable tax document) and any vendor
                registrations (if any) required by Company. Except where expressly inconsistent, the terms of this
                agreement do not modify the Terms of Service and Privacy Policy located on Company’s website. Any
                controversy or claim between the parties arising out of or relating to this agreement, or the breach
                thereof, including its formation, validity, construction, scope, performance, breach, or cancellation
                (including the formation, validity, construction, scope, performance, breach, and or cancellation of
                this arbitration clause), will be resolved through confidential and binding arbitration in Fort Worth,
                Texas in accordance with the JAMS Streamlined Arbitration Rules and Procedures with Optional Appeal
                under the laws of the state of Texas without regard to conflicts of laws principles.`}
                <div className="checkbox-section">
                  <div className={!checkbox ? "checkbox" : "checkbox-selected"} onClick={toggleCheckbox}>
                    {checkbox ? <img className="check" src={check} alt="check-mark" /> : null}
                  </div>
                  <div className="checkbox-text">
                    I have read and agree to the
                    <br />{" "}
                    <span onClick={scrollToText} className="agreement-link">
                      Royalty Share Terms and Conditions
                    </span>
                    .
                  </div>
                </div>
              </div>
              <div className="date-text">
                <span style={{ fontWeight: 600 }}>Effective Date:</span>{" "}
                <Moment format="MMM Do, YYYY">{new Date()}</Moment>
              </div>
              <button
                onClick={submitAgreement}
                type="button"
                disabled={validation}
                className={!validation ? "submit-split-form" : "submit-stale-split-form"}
              >
                {submission && !formSuccess ? <div className="spinner-submit" /> : "Submit"}
              </button>
            </div>
          </form>
        </div>
      ) : (
        formSuccess && (
          <div className="all-revenue">
            <div className="success-section-revenue">
              <div className="check-mark-container">
                <img src={check} alt="check-mark" className="check-mark-revenue" />
              </div>
              <div className="success-title-revenue">Received!</div>
              <div className="success-text-revenue">
                If you don't have an existing Filmsupply account, you will receive an email with login details once we
                have set up your account.
                <br />
                <br />
                If you already have an account, we will notify you when royalties have been applied.
              </div>
            </div>
          </div>
        )
      )}
    </Fragment>
  );
}
