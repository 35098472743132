import React from "react";
import { useFormContext } from "react-hook-form";
import validator from "email-validator";
import { phone } from "phone";
import { ValidationMessage, RequiredMessage } from "./Helpers";

export default function ProducerInfo () {
  const { register, formState: { errors } } = useFormContext(); 

  return (
    <>
      <div className="section-title">
        <div>Producer Info</div>
      </div>
      
      <div className="application-input-section">
        <label className="application-label" htmlFor="producerFirstName">First Name</label>
        <input
          name="producerFirstName"
          {...register("producerFirstName", { required: true, min: 2 })}
          className={`application-input-text ${errors.producerFirstName ? "application-warning" : "correct"}`}
        />
        {errors.producerFirstName && <RequiredMessage />}
      </div>

      <div className="application-input-section">
        <label className="application-label" htmlFor="producerLastName">Last Name</label>
        <input
          name="producerLastName"
          {...register("producerLastName", { required: true, min: 2 })}
          className={`application-input-text ${errors.producerLastName ? "application-warning" : "correct"}`}
        />
        {errors.producerLastName && <RequiredMessage />}
      </div>

      <div className="application-input-section">
        <label className="application-label" htmlFor="producerEmail">Email</label>
        <input
          type="email"
          name="producerEmail"
          {...register("producerEmail", { required: true, validate: value => validator.validate(value) })}
          className={`application-input-text ${errors.producerEmail ? "application-warning" : "correct"}`}
        />
        {errors.producerEmail?.type === 'required' && <RequiredMessage />}
        {errors.producerEmail?.type === 'validate' && <ValidationMessage message="Email is not valid" />}
      </div>

      <div className="application-input-section">
        <label className="application-label" htmlFor="producerPhone">Phone Number</label>
        <input
          name="producerPhone"
          {...register("producerPhone", { required: true, validate: value => phone(value).isValid })}
          className={`application-input-text ${errors.producerPhone ? "application-warning" : "correct"}`}
        />
        {errors.producerPhone?.type === 'required' && <RequiredMessage />}
        {errors.producerPhone?.type === 'validate' && <ValidationMessage message="Phone number is not valid" />}
      </div>

      <div className="application-input-section">
        <label className="application-label" htmlFor="producerCompany">Legal Company Name (optional)</label>
        <input
          name="producerCompany"
          {...register("producerCompany", { required: false })}
          className={`application-input-text ${errors.producerCompany ? "application-warning" : "correct"}`}
        />
      </div>
    </>
  );
}