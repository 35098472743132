import type { FileWithPath } from "react-dropzone";

export enum UploadType {
  Footage = "footage",
  Release = "release"
}

export type FootageType = "color" | "log";

export interface RejectedFile {
  id: string
  file: File
  error: string
}

export interface UploaderFile {
  id: string
  file: FileWithPath
  status: UploadStatus,
  error?: string
}

export enum UploadStatus {
  Queued = 0,
  Processing = 1,
  Success = 2,
  Error = 3,
  Aborted = 4,
  MissingLog = 5,
  MissingColor = 6,
  Invalid = 7
}

export interface UploadedPart {
  PartNumber: number
  ETag: string
}

export type UploadCompletedSignature = (file: File, type: UploadType) => void

export interface ProcessCompletedParams {
  clipsUploaded: number
  releasesUploaded: number
  clipsTotalSize: number
  sendToCuration: boolean
}