import React from "react";
import styles from "./inaccurate-data.module.scss";

export default function InaccurateData() {
  return (
    <div className={styles.inaccurateData}>
      <h2>Inaccurate Data</h2>
      <p>Please navigate back to your <a href="https://www.filmsupply.com/dashboard/footage-manager/shoots">Footage Manager</a> to upload footage. If this error persists,
      please contact <a href="mailto:content@filmsupply.com">content@filmsupply.com</a></p>
    </div>
  );
}